import React, { useState, useEffect } from "react";
import "./App.css";
import ReactHtmlParser from "react-html-parser";
import MainHeader from "./E/Yosemitewestgate/Header/MainHeader";
import MainFooter from "./E/Yosemitewestgate/Footer/MainFooter";
import AppRouting from "./Approuting";
import "./E/Yosemitewestgate/style/common.css";
import MetaTags from "react-meta-tags";
import {
  propertyDataSelector,
  themeSelector,
  getSeoDataSelector,
} from "./Recoil/themeModule";
import { useRecoilState, useRecoilValue } from "recoil";
import { Link } from "react-router-dom";

const App = (props) => {
  const [seoData, setPropertySeodata] = useRecoilState(getSeoDataSelector);
  const [themeData, setThemeData] = useRecoilState(themeSelector);
  const [propertyData, setPropertydata] = useRecoilState(propertyDataSelector);
  const { url: baseUrl } = useRecoilValue(themeSelector);

  const [iconPaths, setIconPaths] = useState([
    "/appleTouchYwl/72X72.png",
    "/appleTouchYwl/114X114.png",
    "/appleTouchYwl/144X144.png",
    "/appleTouchYwl/200X200.png",
  ]);

  useEffect(() => {
    // Function to update the icon paths
    const updateIconPaths = () => {
      setIconPaths([
        "/appleTouchYwl/72X72.png",
        "/appleTouchYwl/114X114.png",
        "/appleTouchYwl/144X144.png",
        "/appleTouchYwl/200X200.png",
      ]);
    };
    // Call the function to update the icon paths
    updateIconPaths();
  }, []);

  document.getElementById("goUp") &&
    document.getElementById("goUp").addEventListener("click", () => {
      const element = document.getElementById("checkAvailability");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    });

  useEffect(() => {
    const scroll = document.querySelector("::-webkit-scrollbar-thumb");
    if (scroll) {
      scroll.style.backgroundColor = "#1b5538";
    }
  }, []);

  return (
    <div className="App">
      <div className="container-fluid px-0">
        <div style={{ minHeight: "100vh" }}>
          <AppRouting propertyData={props.propertyData} />
        </div>
        <div>
          {iconPaths.map((path, index) => (
            <link key={index} rel="apple-touch-icon" href={path} />
          ))}
        </div>
        <>
          <MainFooter
            className="footer-test"
            propertyData={props.propertyData}
          />

          <MainHeader />
          <MetaTags>
            <title>
              {seoData?.meta_title ? ReactHtmlParser(seoData?.meta_title) : ""}
            </title>
            <meta
              name="description"
              content={ReactHtmlParser(seoData.meta_desc)}
            />
            <meta
              property="og:title"
              content={ReactHtmlParser(seoData?.meta_title)}
            />
            <meta
              property="og:description"
              content={ReactHtmlParser(seoData?.meta_desc)}
            />
            <meta name="theme-color" content="#1b5538" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={themeData.url} />

            {(seoData.og_image || seoData.slider_img) && (
              <meta
                property="og:image"
                content={
                  baseUrl +
                  "/gallery-images/properties/" +
                  ReactHtmlParser(seoData.og_image || seoData.slider_img)
                }
              />
            )}

            {(seoData.og_image || seoData.slider_img) && (
              <meta
                name="twitter:image"
                content={
                  baseUrl +
                  "/gallery-images/properties/" +
                  ReactHtmlParser(seoData.og_image || seoData.slider_img)
                }
              />
            )}
            <meta
              property="og:site_name"
              content={propertyData?.property_name}
            />
            <meta
              name="twitter:title"
              content={ReactHtmlParser(seoData?.meta_title)}
            />

            <meta
              name="twitter:description"
              content={ReactHtmlParser(seoData?.meta_desc)}
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content={themeData.url} />
            <link rel="canonical" href={themeData.url} />
          </MetaTags>

          <div
            className="fixedReservationBtn reservationDiv d-xl-none d-lg-none d-sm-block d-xs-block"
            id="goUp"
          >
            <Link>Reservations</Link>
          </div>
        </>
      </div>
    </div>
  );
};

export default App;
