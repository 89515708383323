import React from "react";
import { BiLogoFacebook, BiSolidDownArrow } from "react-icons/bi";
import "./social-Icons.scss";
import { AiFillLinkedin } from "react-icons/ai";
import { FaTripadvisor, FaYelp } from "react-icons/fa";

export default function SocialIconComponent() {
  return (
    <div className="socialIconsStye">
      <div className="social-container container-fluid">
        <ul className="list-unstyled">
          <li title="Facebook">
            <a
              target="_blank"
              href="https://www.facebook.com/hotelcurrent/"
              className="mx-1"
              rel="noreferrer"
            >
              <span className="empty-div">No Text</span>
              <BiLogoFacebook className="icon" />
            </a>
            <span className="cus_tooltip">
              <span>Facebook </span> <BiSolidDownArrow />
            </span>
          </li>

          <li title="linkedin">
            <a
              target="_blank"
              href="https://www.linkedin.com/company/hotel-current-long-beach/"
              className="mx-1"
              rel="noreferrer"
            >
              <span className="empty-div">No Text</span>
              <AiFillLinkedin className="icon" />
            </a>

            <span className="cus_tooltip">
              <span>Linkedin </span> <BiSolidDownArrow />
            </span>
          </li>
          <li title="Yelp">
            <a
              target="_blank"
              href="https://www.yelp.com/biz/hotel-current-long-beach"
              className="mx-1"
              rel="noreferrer"
            >
              <span className="empty-div">No Text</span>
              <FaYelp className="icon" />
            </a>

            <span className="cus_tooltip">
              <span>Yelp </span> <BiSolidDownArrow />
            </span>
          </li>
          <li title="Tripadvisor">
            <a
              target="_blank"
              href="https://www.tripadvisor.com/Hotel_Review-g32648-d77594-Reviews-Hotel_Current-Long_Beach_California.html"
              className="mx-1"
              rel="noreferrer"
            >
              <span className="empty-div">No Text</span>
              <FaTripadvisor className="icon" />
            </a>
            <span className="cus_tooltip">
              <span>Tripadvisor</span> <BiSolidDownArrow />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}
