import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./StartMenu.scss";
import { FaArrowUp } from "react-icons/fa";
import CurrentLogo from "../../HotelCurrent/assets/images/currentLogo.png";
function StartMenuComponent() {
  const [isSticky, setIsSticky] = useState(false);
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [show, setShow] = useState({
    gallery: false,
    events: false,
    destinations: false,
  });

  useEffect(() => {
    window.addEventListener("scroll", myScrollFunc);
  }, []);
  var myScrollFunc = function () {
    var y = window.scrollY;
    if (y >= 500) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setShow({
      gallery: false,
      events: false,
      destinations: false,
    });
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown, so close it
        setShow({
          gallery: false,
          events: false,
          destinations: false,
        });
      }
    };

    if (show.gallery || show.events || show.destinations) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [show.gallery, show.events, show.destinations]);

  return (
    <div
      className={`SideAppStyle StartMenuStyle Saratoga ${
        isSticky ? "sticky" : ""
      }`}
    >
      {window.innerWidth > 1199 ? (
        <div className="desk-menu">
          <div className="grid-container-list"></div>
        </div>
      ) : (
        <div className="header-mob">
          <div className="logo-mob">
            <Link to="/">
              <img src={CurrentLogo} alt="currentIcon" />
            </Link>
          </div>
          <div className="menu-mob"></div>
        </div>
      )}

      <div data-aos="zoom-in-up" data-aos-duration="1000" className="scrollBtn">
        {visible && (
          <button
            className="scrolBtnStyle"
            type="button"
            id="scrollTop"
            title="Scroll To Top"
          >
            scrollBtn
            <FaArrowUp onClick={scrollToTop} />
          </button>
        )}
      </div>
    </div>
  );
}

export default StartMenuComponent;
