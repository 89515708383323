import React, { useEffect, useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";
import { propertyDataSelector } from "./Recoil/themeModule";

const AnalyticsComponent = () => {
  const [propertyData] = useRecoilState(propertyDataSelector);

  const loadAnalyticsScript = useCallback(() => {
    console.log("propertyData:", propertyData);

    if (!propertyData) {
      console.error("propertyData is undefined or null");
      return;
    }
    const scriptTag = document.createElement("script");
    setTimeout(() => {
      const jsonLd = `${propertyData?.conversion_code}`.replace(/"/g, "");
      console.log("jsonLd:", jsonLd);

      scriptTag.text = jsonLd;

      // Use async and defer attributes
      scriptTag.async = true;
      scriptTag.defer = true;

      // Get the first child of the head
      const firstChild = document.head.firstChild;

      // Insert the script tag before the first child
      document.head.insertBefore(scriptTag, firstChild);
    }, 5000);

    // return () => document.head.removeChild(scriptTag);
  }, [propertyData]);

  const memoizedLoadAnalyticsScript = useMemo(
    () => loadAnalyticsScript,
    [loadAnalyticsScript]
  );

  useEffect(() => {
    const cleanup = memoizedLoadAnalyticsScript();
    return cleanup;
  }, [memoizedLoadAnalyticsScript]);

  return null; //
};

export default AnalyticsComponent;
