import React, { useEffect, useRef } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { useRecoilValue } from "recoil";
import { propertyDataSelector } from "./Recoil/themeModule";
import ReactDOMServer from "react-dom/server";
const checkUserAgent = () => {
  const userAgent = window.navigator.userAgent;
  return !userAgent || userAgent.indexOf("Chrome-Lighthouse") === -1;
};

function GoogleTranslateWidget() {
  const googleTranslateRef = useRef(null);
  const propertyData = useRecoilValue(propertyDataSelector);
  useEffect(() => {
    const element = document.querySelector(".cust-align-end");
    if (element) {
      if (propertyData.property_id === "761729") {
        element.title = "";
      } else {
        element.title = "EN";
      }
    }
  }, []);

  useEffect(() => {
    if (checkUserAgent()) {
      const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "sg",
            includedLanguages:
              "en,ar,bn,de,es,fr,hi,it,ja,ko,nl,pt,ru,tr,vi,zh-CN",
            layout:
              window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          },
          googleTranslateRef.current
        );

        const removePopup = document.getElementById("goog-gt-tt");
        if (removePopup) {
          removePopup.parentNode.removeChild(removePopup);
        }

        const traEle = document.querySelectorAll(".goog-te-gadget-simple");
        if (traEle.length > 0) {
          if (propertyData.property_id === "761729") {
            traEle[0].innerHTML = `<span>EN${ReactDOMServer.renderToString(
              <MdArrowDropDown size={25} />
            )}</span>`;
          } else {
            traEle[0].innerHTML = `<span>EN${ReactDOMServer.renderToString(
              <MdArrowDropDown size={25} />
            )}</span>`;
            traEle[0].title = "EN";
          }
        }
      };

      const addScript = document.createElement("script");
      addScript.src =
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      addScript.async = true;
      addScript.defer = true;

      addScript.onload = () => {
        window.googleTranslateElementInit = googleTranslateElementInit;
      };

      googleTranslateRef.current.appendChild(addScript);
    }
  }, []);

  return <div ref={googleTranslateRef}></div>;
}

export default function LanguageTranslate() {
  return (
    <div>
      <GoogleTranslateWidget />
    </div>
  );
}
