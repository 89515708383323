let apidomainurl,
  apiReviewDomainUrl,
  apiInnsightDomainUrl,
  apidomainurlHotelCurrent;

if (
  window.location.href.includes("lbeta") ||
  window.location.href.includes("www") ||
  window.location.href.includes("innatsaratoga") ||
  window.location.href.includes("hotellela") ||
  window.location.href.includes("hotelcurrent") ||
  window.location.href.includes("santacruzcomfortinn")
) {
  apidomainurl = "https://webapi.innsight.com/";
  apiReviewDomainUrl = "https://webapi.keenreputation.com";
  apiInnsightDomainUrl = "https://my.innsight.com/";
} else {
  apidomainurl = "https://android.innstaging.com/";
  apiReviewDomainUrl = "https://webapistaging.keenreputation.com";
  apiInnsightDomainUrl = "https://my.innstaging.com/";
}

// Innatsaratoga , hotellela

// apidomainurl = "https://webapi.innsight.com/";
// apiReviewDomainUrl = "https://webapi.keenreputation.com";
// apiInnsightDomainUrl = "https://my.innsight.com/";

// Innatsaratoga , hotellela

// Hotel current
apidomainurlHotelCurrent = "https://android.innstaging.com/";
// Hotel current

export const getPropReviewComponentUrl = `${apiReviewDomainUrl}/reviews/innsights`;
export const getPropReviewLikeDislikeUrl = `${apiReviewDomainUrl}/reviews/i_like`;
export const getPropReviewDetailsComponentUrl = `${apiReviewDomainUrl}/reviews/innsights_detail`;
export const getPropAddReviewsComponentUrl = `${apiReviewDomainUrl}/reviews/add_reviews`;
export const getPropCountry = `${apiReviewDomainUrl}/reviews/add_reviews_country`;

//Main + other URL
export const getSixPlusBookingUrl = `${apidomainurl}property/six_plus_booking`;
export const getSeoDescriptionUrl = `${apidomainurl}property/SeoDetails`;
export const getYesomiteDescriptionUrl = `${apidomainurl}property/PropertyDetails`;
export const homePageMessageUrl = `${apidomainurl}property/get_homepage_message`;
export const customeApiPmsPageUrl = `${apidomainurl}property/custom_page`;
export const anemetiesIcons = `https://beta.yosemitewestgate.com/gallery-images/amenityicon/`;
export const getPropWeatherUrl = `${apidomainurl}property/weather`;
export const getSubscribeEmailUrl = `${apidomainurl}property/subscribeMeForStandalone`;
export const getPropAmenitiesUrl = `${apidomainurl}property/PropertyAmenityDetails`;
export const getPropNewsUrl = `${apidomainurl}property/news`;
export const getPropCreditCardDataUrl = `${apidomainurl}property/creditcard`;
export const getPropNewsDetailsUrl = `${apidomainurl}property/newsDetails`;
export const getPropJobssUrl = `${apidomainurl}property/jobs`;
export const getPropJobDetailssUrl = `${apidomainurl}property/job_details`;
export const getPropJobFormPostUrl = `${apidomainurl}property/job_details_form_post`;
export const getPropFaqsUrl = `${apidomainurl}property/faq`;
export const getPropCollegeAndUniversityUrl = `${apidomainurl}property/college_university`;
export const getPropContactUsUrl = `${apidomainurl}property/askquestion`;
export const getPropLostAndFoundUrl = `${apidomainurl}property/lostAndFound`;
export const getPropLostFoundDetailsUrl = `${apidomainurl}property/lostFoundDetails`;
export const getPropPersonalInfoReqUrl = `${apidomainurl}property/personalInfoRequestForm`;
export const getPropCleanProtocolsUrl = `${apidomainurl}property/cleaningProtocols`;
export const getPropDestinationAttractionUrl = `${apidomainurl}property/destination_guide`;
export const getPropAttractionDetailssUrl = `${apidomainurl}property/attraction_details_by_url`;
// export const getPropReviewComponentUrl = `${apidomainurl}property/innsights`;
// export const getPropReviewLikeDislikeUrl = `${apidomainurl}property/i_like`;
// export const getPropReviewDetailsComponentUrl = `${apidomainurl}property/innsights_detail`;
// export const getPropAddReviewsComponentUrl =`${apidomainurl}property/add_reviews`;
// export const getPropCountry =  `${apidomainurl}property/add_reviews_country`;
export const getPropPetsUrl = `${apidomainurl}property/pets`;
export const getPropFitnessUrl = `${apidomainurl}property/hotelfitnessroom`;
export const getPropRoomsUrl = `${apidomainurl}reservations`;
export const getPropBookUrl = `${apidomainurl}Reservations/booking`;
export const getBinUrl = `${apidomainurl}Reservations/book`;
export const getBookingData = `${apidomainurl}Reservations/reservationConfirmation`;
export const resendReservationMail = `${apidomainurl}Reservations/resendReservationMail`;
export const addCalendarURL = `${apidomainurl}Reservations/calendar_event`;
export const editReservationURL = `${apiInnsightDomainUrl}reservation-status`;
export const getModificationURL = `${apiInnsightDomainUrl}modify-booking`;
export const getNonModificationURL = `${apiInnsightDomainUrl}reservation-status`;
export const getEditGuestNameURL = `${apidomainurl}Reservations/updateGuestName`;
export const getEmailToGuestURL = `${apidomainurl}Reservations/resendReservationEmailForOtherGuest`;
export const getCurrencyConverterURL = `${apidomainurl}Reservations/currencyConverter`;
export const getSpecialRequestURL = `${apidomainurl}Reservations/updateSpecialRequest`;
export const getDietaryRestrictionURL = `${apidomainurl}Reservations/updateDietaryRestriction`;
export const getSubmitEstimatedArrivalURL = `${apidomainurl}Reservations/updateEstimatedArrival`;
export const myURL = `${apiInnsightDomainUrl}`;
export const assetsUrl = `https://beta.yosemitewestgate.com/assets`;
// export const getCurrencyUrl = `${apidomainurl}Reservations/updateCurrecny`;
export const getCurrencyUrl = `${apidomainurl}property/currency`;
export const getPropertyURL = `${apidomainurl}Reservations/getPropertyData`;
export const getPropRoomDetailsUrl = `${apidomainurl}property/roomtype`;
export const getPropTransportUrl = `${apidomainurl}property/transport_commute`;
export const getPropTopReasonToStayUrl = `${apidomainurl}property/top_reasons_to_stay`;
export const getPropEventsUrl = `${apidomainurl}property/events`;
export const getPropEventsDetailsUrl = `${apidomainurl}property/eventsDetails`;
export const getThingToDoDetailsUrl = `${apidomainurl}property/things_to_do`;
export const getPropAdaAccesibilityUrl = `${apidomainurl}property/ada_amenities_services`;
export const getPropVideoUrl = `${apidomainurl}property/video`;
export const getPropVirtualToursUrl = `${apidomainurl}property/panographics`;
export const getPropGuestRoomsUrl = `${apidomainurl}property/guestrooms`;
export const getPropDirectionMapUrl = `${apidomainurl}property/directions`;
export const getPropFriendPageUrl = `${apidomainurl}property/friends`;
export const getPropFriendDetailsPageUrl = `${apidomainurl}property/friend_details`;
export const getPropPackagesPageUrl = `${apidomainurl}property/packages`;
export const getPropPoolPageUrl = `${apidomainurl}property/pool`;
export const getPropPackagesDetailsPageUrl = `${apidomainurl}property/packagesDetails`;
export const getPropArtCultureUrl = ``;
export const getPropEntertainmentUrl = `${apidomainurl}property/entertainment_near`;
export const getPropParkingUrl = `${apidomainurl}property/hotelParking`;
export const getPropLocalServicesUrl = `${apidomainurl}property/local_services`;
export const getPropGalleryUrl = `${apidomainurl}property/gallery`;
export const getPropPackageFormUrl = `${apidomainurl}property/package_contact`;
export const getPropEventContactUrl = `${apidomainurl}property/event_contact`;
export const getPropSiteMapUrl = `${apidomainurl}property/sitemap`;
export const getPropBlogPageUrl = `${apidomainurl}property/blog`;
export const getPropBlogDetailsUrl = `${apidomainurl}property/blog_details`;
export const getPropCreditCardUrl = `${apidomainurl}property/blog_details`;
export const getPropUnSubscribedUrl = `${apidomainurl}property/unsubsribe_me`;
export const getPropHomePopUrl = `${apidomainurl}property/popup_messages`;
export const getPropStateUrl = `${apidomainurl}property/get_states_post`;
export const getPropCityeUrl = `${apidomainurl}property/get_cities_post`;
export const getPropRfpUrl = `${apidomainurl}form/rfp_form`;
export const getPropRfpUrlHotelCurrent = `${apidomainurlHotelCurrent}form/standalone_rfp_form`;
