import { selector } from "recoil";
import {
  themeConfig,
  getSeoData,
  getThemeName,
  propertyData,
  imageLoader,
  getSeoId,
  getSideNav,
  sideMenu,
} from "./index";
import { reservationUrlData } from "./themeAtoms";

export const themeSelector = selector({
  key: "themeSelector",
  get: ({ get }) => {
    let themeName = get(getThemeName);
    // let propertyId = get(getPropertyId);
    let themeConfiguration = get(themeConfig);
    return themeConfiguration[themeName];
  },
  set: ({ set }, newValue) => {
    set(getThemeName, newValue);
  },
});
// dummy comment

// export const getSideNavSelector = selector({  key: "getSideNavSelector",  get: ({ get }) => {    let sideNav = get(getSideNav);    return sideNav;  },  set: ({ set }, newSideNav) => {    set(getSideNav, newSideNav);  },});

export const propertyDataSelector = selector({
  key: "propertyDataSelector",
  get: ({ get }) => {
    let propertyDetails = get(propertyData);
    return propertyDetails;
  },
  set: ({ set }, newPropertyData) => {
    set(propertyData, newPropertyData);
  },
});

export const getSeoDataSelector = selector({
  key: "getSeoDataSelector",
  get: ({ get }) => {
    let seoData = get(getSeoData);
    return seoData;
  },
  set: ({ set }, newSeoData) => {
    set(getSeoData, newSeoData);
  },
});

//getSeoID

export const getSeoIdSelector = selector({
  key: "getSeoIdSelector",
  get: ({ get }) => {
    let seoId = get(getSeoId);
    console.log("selectore data", seoId);
    return seoId;
  },
  set: ({ set }, newSeoId) => {
    console.log("set", newSeoId);
    set(getSeoId, newSeoId);
  },
});

export const loaderImageSelector = selector({
  key: "loaderImageSelector",
  get: ({ get }) => {
    let loader = get(imageLoader);
    return loader;
  },
  set: ({ set }, newImageLoader) => {
    set(imageLoader, newImageLoader);
  },
});

export const getSideNavSelector = selector({
  key: "getSideNavSelector",
  get: ({ get }) => {
    let sideNav = get(getSideNav);
    return sideNav;
  },
  set: ({ set }, newSideNav) => {
    set(getSideNav, newSideNav);
  },
});

export const getSideMenuSelector = selector({
  key: "getSideMenuSelector",
  get: ({ get }) => {
    let sideMenuStatus = get(sideMenu);
    return sideMenuStatus;
  },
  set: ({ set }, newSideMenu) => {
    set(sideMenu, newSideMenu);
  },
});
