import React, { useState, useEffect, useCallback, memo } from "react";
import Components from "./componentIndex";
import {
  getYesomiteData,
  getSeoDescriptionData,
} from "./DataLayer/datalayerUtilities";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  themeSelector,
  propertyDataSelector,
  getSeoDataSelector,
  loaderImageSelector,
  getSeoIdSelector,
} from "./Recoil/themeModule";
import axios from "axios";
import FaviInn from "./E/InnAtSaratoga/assets/Icons/faviconINN.ico";
import FaviYWl from "./E/Yosemitewestgate/assets/Icons/faviconYwl.ico";
import HotelCurrentFavi from "./E/HotelCurrent/assets/Icons/hotelcurrentfavi.ico";
import HotelLelafavi from "./E/Hotellela/assets/Icons/favicon.ico";
import Comfortfavi from "./E/SantaCruzComfortInn/assets/Icons/favicon.ico";
import SchemeComponent from "./GTMCodeConversion/index";
import AnalyticsComponent from "./analtics";
import AnalyticsComponentBody from "./analyticsbody";

const App = () => {
  axios.defaults.withCredentials = false;
  const [themeData, setThemeData] = useRecoilState(themeSelector);
  const [propertyData, setPropertydata] = useRecoilState(propertyDataSelector);
  const [imageLoader, setImageLoader] = useRecoilState(loaderImageSelector);
  const getSeoId = useRecoilValue(getSeoIdSelector);
  const [seoData, setPropertySeodata] = useRecoilState(getSeoDataSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [reInitialized, setReInitialized] = useState(false);
  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);
  const [showChatbot, setShowChatbot] = useState(false);

  const checkForPageSpeedInsights = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    // Method 1: Check user agent string
    const detectedPageSpeedInsights =
      /Chrome-Lighthouse/i.test(userAgent) ||
      /chrome-lighthouse/i.test(userAgent);

    // Method 2: Check for specific HTTP headers
    const isPageSpeedInsights =
      document.head.querySelector('meta[name="X-PageSpeed-Insights"]') ||
      document.head.querySelector('meta[name="PageSpeed"]');

    const shouldRender = !detectedPageSpeedInsights && !isPageSpeedInsights;
    setShouldRenderComponent(shouldRender);
  };

  // setting property data
  const fetchProperties = useCallback(async (propertyId) => {
    setIsLoading(true);
    try {
      const response = await getYesomiteData(propertyId);
      if (response.length > 0) {
        // add property subdomain as className to the body
        document
          .querySelector("body")
          .classList.add(`${response[0].propertySubdomain}`);
        // add property subdomain as className to the body
        setPropertydata(response[0]);
        document.title = response[0].property_name;
        localStorage.setItem("latitude", response[0].latitude);
        localStorage.setItem("longitude", response[0].longitude);
        sessionStorage.setItem("adapropertyid", response[0]?.adapropertyid);
        sessionStorage.setItem("themeName", response[0]?.propertySubdomain);
        sessionStorage.setItem("propertyId", response[0]?.property_id);
        sessionStorage.setItem("city", response[0]?.city);
        sessionStorage.setItem("name", response[0]?.name);
        sessionStorage.setItem("state", response[0]?.state);
        sessionStorage.setItem("country", response[0]?.country);
        sessionStorage.setItem("sorjen_ccid", response[0]?.sorjen_ccid);
        sessionStorage.setItem("sorjen_pixel", response[0]?.sorjen_pixel);
        sessionStorage.setItem("keenpropertyid", response[0]?.keenpropertyid);
        updateFavIcon(response[0]?.property_id);
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);
  // setting property data

  const updateFavIcon = (propertyId) => {
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = getFaviconUrl(propertyId);
    document.querySelector("head").appendChild(link);
  };

  const getFaviconUrl = (propertyId) => {
    switch (propertyId) {
      case "265":
        return FaviYWl;
      case "761723":
        return FaviInn;
      case "726622":
        return HotelCurrentFavi;
      case "761729":
        return HotelLelafavi;
      case "5002":
        return Comfortfavi;
      default:
        return <div>Loading...</div>;
    }
  };

  const fetchSeoProperties = useCallback(async (payLoadSeodId) => {
    setImageLoader(true);
    const response = await getSeoDescriptionData(payLoadSeodId);
    // console.log("response seo--->", response);
    if (response && response.data) {
      setPropertySeodata(response.data);
      setImageLoader(false);
      return;
    }
    setImageLoader(false);
  }, []);

  // External scripts

  useEffect(() => {
    checkForPageSpeedInsights();
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/" && propertyData?.is_rct === "1") {
      setTimeout(() => {
        import("./rctForHomepage.js").then(() => {
          console.log("rctForHomepage.js imported");
        }, 5000);
      });
    }
  }, [propertyData.is_rct]);

  useEffect(() => {
    if (propertyData?.ada_widget_status === "1") {
      setTimeout(() => {
        import("./adaTray.js").then(() => {
          console.log("adaTray.js imported");
        });
      }, 7000);
    }
  }, [propertyData.ada_widget_status]);

  // External scripts

  useEffect(() => {
    const getCurrentTheme = (mode = "production") => {
      let themeName = "";
      // let themeName = "yosemitewestgate";
      // let themeName = "innatsaratoga";
      // let themeName = "hotelcurrent";
      // let themeName = "hotellela";
      // let themeName = "santacruzcomfortinn";
      sessionStorage.clear();
      if (mode === "production") {
        let hostName = window.location.hostname;
        themeName = hostName.split(".")[1];
      }
      setThemeData(themeName);
      setReInitialized(true);
    };
    getCurrentTheme();
  }, []);

  useEffect(() => {
    if (themeData?.propertyId) {
      fetchProperties(themeData?.propertyId);
    }
  }, [themeData?.propertyId, fetchProperties]);

  useEffect(() => {
    if (getSeoId) {
      fetchSeoProperties(getSeoId);
    }
  }, [getSeoId, fetchSeoProperties, propertyData.property_id]);

  useEffect(() => {
    localStorage.setItem("HomePage", false);
  }, []);

  const ComponentToRender = Components[themeData?.theme];

  useEffect(() => {
    if (ComponentToRender) {
      setShowChatbot(true);
    }
  }, [ComponentToRender]);

  if (imageLoader) {
    return (
      <div className="vh-100 bg-loader w-100 d-flex">
        <div className="loader">Loading</div>
      </div>
    );
  }

  if (!ComponentToRender) {
    return (
      <div className="vh-100 bg-loader w-100 d-flex">
        <div className="loader">Loading</div>
      </div>
    );
  }

  return (
    <>
      {(propertyData?.maintanceWarning || propertyData?.maintanceData) && (
        <div class="col-lg-12 maintanceWarmimg PL0 PR0">
          <div class="container-fluid text-center PL0 PR0">
            <div class="maintance_content">
              <marquee>
                <p>
                  {propertyData?.maintanceWarning?.text_msg ||
                    propertyData?.maintanceData?.text_msg}
                </p>
              </marquee>
            </div>
          </div>
        </div>
      )}

      {shouldRenderComponent && (
        <>
          {propertyData &&
            propertyData?.conversion_code &&
            propertyData?.gtm_body_code !== "" && (
              <>
                <AnalyticsComponent />
                <AnalyticsComponentBody />
              </>
            )}
        </>
      )}

      <SchemeComponent />
      <ComponentToRender propertyData={propertyData} />
    </>
  );
};

export default memo(App);
