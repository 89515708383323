import React, { useState, useEffect } from "react";
import "./App.css";
import AppRouting from "./Approuting";
import MainFooter from "./E/HotelCurrent/Footer/MainFooter";
import MainHeader from "./E/HotelCurrent/Header/MainHeader";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  loaderImageSelector,
  propertyDataSelector,
} from "./Recoil/themeModule";

export default function HotelCurrentHomepage(props) {
  const [propertyData, setPropertydata] = useRecoilState(propertyDataSelector);
  const imageLoader = useRecoilValue(loaderImageSelector);
  const [isHide, setIsHide] = useState(true);
  const [showComponent, setShowComponent] = useState(false);
  setTimeout(() => setIsHide(false), 2000);

  useEffect(() => {
    setTimeout(() => {
      setShowComponent(true);
    }, 1000);
  }, []);

  const [iconPaths, setIconPaths] = useState([
    "/appleTouchHC/72X72.png",
    "/appleTouchHC/114X114.png",
    "/appleTouchHC/144X144.png",
    "/appleTouchHC/200X200.png",
  ]);

  useEffect(() => {
    // Function to update the icon paths
    const updateIconPaths = () => {
      // Modify the iconPaths state as needed
      setIconPaths([
        "/appleTouchHC/72X72.png",
        "/appleTouchHC/114X114.png",
        "/appleTouchHC/144X144.png",
        "/appleTouchHC/200X200.png",
      ]);
    };

    // Call the function to update the icon paths
    updateIconPaths();
  }, []);

  const [isFooterLoaded, setFooterLoaded] = useState(false);

  useEffect(() => {
    // Simulate an asynchronous operation to load the header
    setTimeout(() => {
      setFooterLoaded(true);
    }, 1200); // Adjust the timeout value as needed
  }, []);

  document.getElementById("goUp") &&
    document.getElementById("goUp").addEventListener("click", () => {
      const element = document.getElementById("checkAvailability");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    });

  return (
    <div className="App">
      {!imageLoader ? (
        <div className="container-fluid px-0">
          <MainHeader />
          <div style={{ minHeight: "100vh" }}>
            <AppRouting propertyData={props.propertyData} />
          </div>
          <div>
            <>
              {iconPaths.map((path, index) => (
                <link key={index} rel="apple-touch-icon" href={path} />
              ))}
            </>
          </div>
          <>
            {isFooterLoaded && (
              <MainFooter
                className="footer-test"
                propertyData={props.propertyData}
              />
            )}
            <div
              className="fixedReservationBtn reservationDiv d-xl-none d-lg-none d-md-block d-sm-block d-xs-block"
              id="goUp"
            >
              <a>Reservations</a>
            </div>
            <label
              htmlFor=" mini-popup-resolution"
              id=" mini-popup-resolution"
              className="sr-only d-none"
            >
              hidden label
            </label>
          </>
        </div>
      ) : null}
      <label
        htmlFor=" mini-popup-resolution"
        id=" mini-popup-resolution"
        className="sr-only d-none"
      >
        hidden label
      </label>
    </div>
  );
}
