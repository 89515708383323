// new code Nora for solving import issue
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import "./bannerContainerStyles.scss";
import ReactHtmlParser from "react-html-parser";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  themeSelector,
  propertyDataSelector,
} from "../../../Recoil/themeModule";
import { useMediaQuery } from "react-responsive";
import ProgressiveImage from "react-progressive-graceful-image";
import placeholderSrc from "../../CommonAssets/images/placeholdr.jpg";
import Default_Desk from "../../CommonAssets/images/Default_Desk.png";
import Default_Mob from "../../CommonAssets/images/Default_Mob.png";

// Import lazily
const WrappperCheckAvailibilityYosemitewestgate = React.lazy(() =>
  import("../../CheckAvailibilty/WrapperCheckAvailibity")
);
const WrapperCheckAvailibilityHotelLela = React.lazy(() =>
  import(
    "../../MarketingCheckAvailibility/MarketingCheckAvailibility-hotel-lela/WrapperCheckAvailibity"
  )
);
const WrapperCheckAvailibilityInnatsaratoga = React.lazy(() =>
  import(
    "../../MarketingCheckAvailibility/MarketingCheckAvailibility-innatsaratoga/WrapperCheckAvailibity"
  )
);
const WrapperCheckAvailibilityHotelCurrent = React.lazy(() =>
  import(
    "../../MarketingCheckAvailibility/MarketingCheckAvailibility-hotel-current/WrapperCheckAvailibity"
  )
);

const WrapperCheckAvailibilityComfortInnSC = React.lazy(() =>
  import(
    "../../MarketingCheckAvailibility/MarketingCheckAvailibility-Comfort-Inn-SC/WrapperCheckAvailibity"
  )
);

const BannerContainer = (props) => {
  const [propertyData, setPropertydata] = useRecoilState(propertyDataSelector);
  const [stateImage, setStateImage] = useState("");
  const [stateMobileImage, setStateMobileImage] = useState("");
  const [titleImg, setTitleImg] = useState(props.seoData.slider_img_text);
  const [showMeta, setShowMeta] = useState(false);
  const { url: baseUrl } = useRecoilValue(themeSelector);
  const CurrentWebUrl = window.location.href;
  const [imageHoverText, setImageHoverText] = useState("");
  const [themeData, setThemeData] = useRecoilState(themeSelector);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Trigger the fade-in effect after a short delay
    const timeoutId = setTimeout(() => {
      setFadeIn(true);
    }, 1000);

    // Clean up the timeout when the component unmounts or the effect changes
    return () => clearTimeout(timeoutId);
  }, []);

  let headerSmaller = document.querySelector(".App");
  const idTarget = document.querySelector(".min-height-img");

  useEffect(() => {
    setTimeout(() => {
      setShowMeta(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (props.seoData.slider_img_text) {
      setImageHoverText(
        props.seoData.slider_img_text
          .toLowerCase()
          .split(" ")
          .map((word) => {
            return word[0]?.toUpperCase() + word.substring(1);
          })
          .join(" ")
      );
    }
  }, []);

  const scrolFn = (entries, observer) => {
    const [entry] = entries;
    let screenWidth = window.screen.availWidth;
    if (screenWidth > 1199) {
      if (!entry.isIntersecting && window.scrollY > 200)
        headerSmaller.classList.add("smaller");
      else headerSmaller.classList.remove("smaller");
    }
  };

  let rctDiv = document.querySelector("#aaaWidget");
  let bodyRctClassName = document.body;
  if (window.location.pathname.length > 1 && rctDiv) {
    rctDiv.remove();
    bodyRctClassName.classList.remove("rct_wdgt");
    bodyRctClassName.classList.add("p-0");
  }

  const scrolOptns = {
    root: null,
    threshold: 0.5,
    rootMargin: "0px",
  };

  const scrolObs = new IntersectionObserver(scrolFn, scrolOptns);
  idTarget && scrolObs.observe(idTarget);

  const isDesktop = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  let bannerImg = "";
  let img = "";
  setTimeout(() => {
    let ImageName = props.seoData.slider_img;
    if (ImageName) {
      bannerImg = baseUrl + "/gallery-images/properties/" + ImageName;
      if (ImageName.split(".").pop() === "jpg") {
        img = ImageName.replace("jpg", "webp");
      } else if (ImageName.split(".").pop() === "jpeg") {
        img = ImageName.replace("jpeg", "webp");
      } else if (ImageName.split(".").pop() === "png") {
        img = ImageName.replace("png", "webp");
      } else {
        img = props.seoData.slider_img;
      }
    }
    converTitleCase();
    setStateImage(img);
  }, 1);

  let bannerMobImage = "";
  let mobileImg = "";
  setTimeout(() => {
    let MobileImageName = props.seoData.slider_img_mobile;
    if (MobileImageName) {
      bannerMobImage =
        baseUrl + "/gallery-images/original/properties/" + MobileImageName;
      if (MobileImageName.split(".").pop() === "jpg") {
        mobileImg = MobileImageName.replace("jpg", "webp");
      } else if (MobileImageName.split(".").pop() === "jpeg") {
        mobileImg = MobileImageName.replace("jpeg", "webp");
      } else if (MobileImageName.split(".").pop() === "png") {
        mobileImg = MobileImageName.replace("png", "webp");
      } else {
        mobileImg = props.seoData.slider_img_mobile;
      }
    }
    converTitleCase();
    setStateMobileImage(mobileImg);
  }, 1);

  const result = function () {
    if (props.seoData.staticImgUrl) {
      return {
        jpgImage: `${ReactHtmlParser(props.seoData.slider_img)}`,
        webpImage: `${ReactHtmlParser(props.seoData.slider_webp_img)}`,
      };
    } else {
      return {
        jpgImage: `${baseUrl}/gallery-images/properties/${ReactHtmlParser(
          props.seoData.slider_img
        )}`,
        webpImage: `${baseUrl}/gallery-images/properties/${stateImage}`,
      };
    }
  };
  const resultRoomDetails = function () {
    return {
      jpgImage: `${baseUrl}/${props.RoomDetailsData.room_images[0].image_medium_url}`,
      webpImage: `${baseUrl}/gallery-images/properties/original/${props.RoomDetailsData.room_images[0].image_name.replace(
        "jpg",
        "webp"
      )}`,
    };
  };

  const mobResult = function () {
    if (props.seoData.staticImgUrl) {
      return {
        jpgImage: `${ReactHtmlParser(props.seoData.slider_img_mobile)}`,
        webpImage: `${ReactHtmlParser(props.seoData.slider_webp_img_mobile)}`,
      };
    } else {
      return {
        jpgImage: `${baseUrl}/gallery-images/properties/${ReactHtmlParser(
          props.seoData.slider_img_mobile
        )}`,
        webpImage: `${baseUrl}/gallery-images/properties/${stateMobileImage}`,
      };
    }
  };

  const converTitleCase = () => {
    if (titleImg) {
      let convertCase = titleImg
        .trim()
        .toLocaleLowerCase()
        .split(" ")
        .map((val) => {
          return (val = val[0]?.toUpperCase() + val.slice(1));
        })
        .join(" ");
      setTitleImg(convertCase);
    }
  };

  setTimeout(() => {
    const loadList = Array.from(document.querySelectorAll(".loading"));
    for (const listLoad of loadList) {
      listLoad.className.includes("loading") &&
        listLoad.classList.remove("loading");
      listLoad.classList.add("loaded");
    }
  }, 3000);

  const MemoizedCheckAvailabilityYosemite = React.memo(
    () => (
      <WrappperCheckAvailibilityYosemitewestgate
        inputParams={props.inputParams}
        setcheckAvailability={props.setcheckAvailability}
      />
    ),
    [props.inputParams, props.setcheckAvailability]
  );

  const MemoizedCheckAvailabilityComfortInnSC = React.memo(
    () => (
      <WrapperCheckAvailibilityComfortInnSC
        inputParams={props.inputParams}
        setcheckAvailability={props.setcheckAvailability}
      />
    ),
    [props.inputParams, props.setcheckAvailability]
  );
  const MemoizedCheckAvailabilityHotelLela = React.memo(
    () => (
      <WrapperCheckAvailibilityHotelLela
        inputParams={props.inputParams}
        setcheckAvailability={props.setcheckAvailability}
      />
    ),
    [props.inputParams, props.setcheckAvailability]
  );
  const MemoizedCheckAvailabilityInnatsaratoga = React.memo(
    () => (
      <WrapperCheckAvailibilityInnatsaratoga
        inputParams={props.inputParams}
        setcheckAvailability={props.setcheckAvailability}
      />
    ),
    [props.inputParams, props.setcheckAvailability]
  );

  const MemoizedCheckAvailabilityHotelCurrent = React.memo(
    () => (
      <WrapperCheckAvailibilityHotelCurrent
        inputParams={props.inputParams}
        setcheckAvailability={props.setcheckAvailability}
      />
    ),
    [props.inputParams, props.setcheckAvailability]
  );

  return (
    <div>
      {props.RoomDetailsData ? (
        <div className="displayCA">
          <div className="min-height-img overlay-bg-layer">
            {props.RoomDetailsData.room_name && window.innerWidth > 1199 && (
              <div className={`fade-in ${fadeIn && "active"}`}>
                <div className="text-white banner-title-bg-color d-inline-block mt-2 fs-xs-16 px-2 px-md-4 ls-2 position-absolute mb-2 test">
                  <span>{props.RoomDetailsData.room_name}</span>
                </div>
              </div>
            )}
            {props.RoomDetailsData.room_name &&
              window.innerWidth > 992 &&
              window.innerWidth <= 1199 && (
                <div className={`fade-in ${fadeIn && "active"} cus-position`}>
                  <div className="text-white banner-title-bg-color d-inline-block mt-2 fs-xs-16 px-2 px-md-4 ls-2 mb-2 test">
                    <span>{props.RoomDetailsData.room_name}</span>
                  </div>
                </div>
              )}

            {props.RoomDetailsData.room_images[0].image_medium_url ? (
              <div className="test-heigh" style={{ position: "relative" }}>
                {props.RoomDetailsData.room_name &&
                  window.innerWidth <= 992 && (
                    <div
                      className={`fade-in ${
                        fadeIn ? "active" : ""
                      } cus-position`}
                    >
                      <div className="text-white cus-banner-title-bg-color">
                        <div>{props.RoomDetailsData.room_name}</div>
                      </div>
                    </div>
                  )}
                {isDesktop && (
                  <ProgressiveImage
                    src={resultRoomDetails()}
                    placeholder={placeholderSrc}
                  >
                    {({ src = resultRoomDetails() }, loading) => {
                      return (
                        <picture
                          className="bg-gradient"
                          title={props.RoomDetailsData.room_name}
                        >
                          <source
                            className={`w-100 bg-picture-tag banner-xs banner-xl-size mx-0 mt-0 d-md-block d-lg-block d-xl-block ${
                              loading ? " loading" : " loaded"
                            }`}
                            srcSet={src.webpImage}
                            type="image/webp"
                            alt={
                              props.RoomDetailsData.room_name || "banner img"
                            }
                            height="100%"
                            loading="lazy"
                            title={props.RoomDetailsData.room_name}
                          ></source>
                          <source
                            className={`w-100 bg-picture-tag banner-xs banner-xl-size mx-0 mt-0 d-md-block d-lg-block d-xl-block ${
                              loading ? " loading" : " loaded"
                            }`}
                            srcSet={src.jpgImage}
                            alt={
                              props.RoomDetailsData.room_name || "banner img"
                            }
                            height="100%"
                            loading="lazy"
                            title={props.RoomDetailsData.room_name}
                          ></source>
                          <img
                            className={`w-100 bg-picture-tag banner-xs ipad-style banner-xl-size mx-0 mt-0 d-md-block d-lg-block d-xl-block ${
                              loading ? "loading" : "loaded"
                            }`}
                            style={{
                              height: window.innerWidth <= 1199 && "400px",
                              objectFit:
                                window.innerWidth <= 1199 && "cover",
                            }}
                            src={src.jpgImage}
                            type="image/jpg"
                            loading="lazy"
                            title={props.RoomDetailsData.room_name}
                            alt={
                              props.RoomDetailsData.room_name || "banner img"
                            }
                          />
                        </picture>
                      );
                    }}
                  </ProgressiveImage>
                )}
              </div>
            ) : (
              isDesktop && (
                <div className="BanDetImg">
                  <div className="w-100">
                    <img
                      className="W100 card-img-top"
                      src={Default_Desk}
                      style={{
                        height: window.innerWidth <= 1199 && "400px",
                        objectFit: window.innerWidth <= 1199 && "cover",
                      }}
                      width="1500"
                      title="No Image"
                      alt="No Img"
                    />
                  </div>
                </div>
              )
            )}

            {props.RoomDetailsData.room_images[0].image_medium_url ? (
              <div style={{ position: "relative" }}>
                {props.RoomDetailsData.room_name && isMobile && (
                  <div
                    className={`fade-in ${fadeIn ? "active" : ""} cus-position`}
                  >
                    <div className="text-white cus-banner-title-bg-color">
                      <div>{props.RoomDetailsData.room_name}</div>
                    </div>
                  </div>
                )}
                {isMobile && (
                  <ProgressiveImage
                    src={resultRoomDetails()}
                    placeholder={placeholderSrc}
                  >
                    {({ src = resultRoomDetails() }, loading) => {
                      return (
                        <picture
                          className="bg-gradient"
                          title={props.RoomDetailsData.room_name}
                        >
                          <source
                            className={`w-100 h-100 banner-xs mx-0  mt-0 d-block d-md-none d-lg-none d-xl-none ${
                              loading ? " loading" : " loaded"
                            }`}
                            srcSet={src.webpImage}
                            type="image/webp"
                            alt="Banner_Image"
                            loading="lazy"
                            title={props.RoomDetailsData.room_name}
                          ></source>
                          <source
                            className={`w-100 h-100 banner-xs mx-0  mt-0 d-block d-md-none d-lg-none d-xl-none ${
                              loading ? "loading" : "loaded"
                            }`}
                            srcSet={src.jpgImage}
                            type="image/jpg"
                            alt="Banner_Image"
                            loading="lazy"
                            title={props.RoomDetailsData.room_name}
                          ></source>
                          <img
                            style={{ height: "403px", objectFit: "cover" }}
                            className={`w-100 banner-xs mx-0 mt-0 d-block d-md-none d-lg-none d-xl-none ${
                              loading ? "loading" : "loaded"
                            }`}
                            src={src.jpgImage}
                            alt="Banner_Image"
                            type="image/jpg"
                            loading="lazy"
                            title={props.RoomDetailsData.room_name}
                          />
                        </picture>
                      );
                    }}
                  </ProgressiveImage>
                )}
              </div>
            ) : (
              isMobile && (
                <div className="BanDetImg">
                  <div className="w-100">
                    <img
                      className="W100 card-img-top"
                      src={Default_Mob}
                      title="No Image"
                      alt="No Img"
                    />
                  </div>
                </div>
              )
            )}
            <div className="inner-text-overlay"></div>
          </div>

          <>
            {themeData.is_marketing === "0" ? (
              <div id="showMe">
                {themeData.theme === "yosemitewestgate" ? (
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <MemoizedCheckAvailabilityYosemite />
                  </React.Suspense>
                ) : null}
              </div>
            ) : (
              <div id="showMe">
                {themeData.theme === "hotelcurrent" ? (
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <MemoizedCheckAvailabilityHotelCurrent />
                  </React.Suspense>
                ) : themeData.theme === "hotellela" ? (
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <MemoizedCheckAvailabilityHotelLela />
                  </React.Suspense>
                ) : themeData.theme === "santacruzcomfortinn" ? (
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <MemoizedCheckAvailabilityComfortInnSC />
                  </React.Suspense>
                )  : themeData.theme === "innatsaratoga" ? (
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <MemoizedCheckAvailabilityInnatsaratoga />
                  </React.Suspense>
                ) : null}
              </div>
            )}
          </>
        </div>
      ) : (
        <div className="displayCA">
          <div className="min-height-img overlay-bg-layer">
            {props.seoData.slider_img_text && window.innerWidth > 1199 && (
              <div className={`fade-in ${fadeIn && "active"}`}>
                <div className="text-white banner-title-bg-color d-inline-block mt-2 fs-xs-16 px-2 px-md-4 ls-2 position-absolute mb-2 test">
                  <span>{ReactHtmlParser(props.seoData?.slider_img_text)}</span>
                </div>
              </div>
            )}
            {props.seoData.slider_img_text &&
              window.innerWidth > 992 &&
              window.innerWidth <= 1199 && (
                <div className={`fade-in ${fadeIn && "active"} cus-position`}>
                  <div className="text-white banner-title-bg-color d-inline-block mt-2 fs-xs-16 px-2 px-md-4 ls-2 mb-2 test">
                    <span>
                      {ReactHtmlParser(props.seoData.slider_img_text)}
                    </span>
                  </div>
                </div>
              )}

            {props.seoData.slider_img ? (
              <div className="test-heigh" style={{ position: "relative" }}>
                {props.seoData.slider_img_text && window.innerWidth <= 992 && (
                  <div
                    className={`fade-in ${fadeIn ? "active" : ""} cus-position`}
                  >
                    <div className="text-white cus-banner-title-bg-color">
                      <div>
                        {ReactHtmlParser(props.seoData.slider_img_text)}
                      </div>
                    </div>
                  </div>
                )}
                {isDesktop && (
                  <ProgressiveImage src={result()} placeholder={placeholderSrc}>
                    {({ src = result() }, loading) => {
                      return (
                        <picture className="bg-gradient" title={titleImg}>
                          <source
                            className={`w-100 bg-picture-tag banner-xs banner-xl-size mx-0 mt-0 d-md-block d-lg-block d-xl-block ${
                              loading ? " loading" : " loaded"
                            }`}
                            srcSet={src.webpImage}
                            type="image/webp"
                            alt={titleImg || "banner img"}
                            height="100%"
                            loading="lazy"
                            title={titleImg}
                          ></source>
                          <source
                            className={`w-100 bg-picture-tag banner-xs banner-xl-size mx-0 mt-0 d-md-block d-lg-block d-xl-block ${
                              loading ? " loading" : " loaded"
                            }`}
                            srcSet={src.jpgImage}
                            // type="image/jpg"
                            alt={titleImg || "banner img"}
                            height="100%"
                            loading="lazy"
                            title={titleImg}
                          ></source>
                          <img
                            className={`w-100 bg-picture-tag banner-xs ipad-style banner-xl-size mx-0 mt-0 d-md-block d-lg-block d-xl-block ${
                              loading ? " loading" : " loaded"
                            }`}
                            src={src.jpgImage}
                            type="image/jpg"
                            height="100%"
                            loading="lazy"
                            title={titleImg}
                            alt={titleImg || "banner img"}
                          />
                        </picture>
                      );
                    }}
                  </ProgressiveImage>
                )}
              </div>
            ) : (
              isDesktop && (
                <div className="BanDetImg">
                  <div className="w-100">
                    <img
                      className="W100 card-img-top"
                      src={Default_Desk}
                      style={{
                        height: window.innerWidth <= 1199 && "400px",
                        objectFit: "cover",
                        objectPosition: window.innerWidth <= 1199 ? "" : "center",
                      }}
                      width="1500"
                      title="No Image"
                      alt="No Img"
                    />
                  </div>
                </div>
              )
            )}

            {props.seoData.slider_img_mobile ? (
              <div style={{ position: "relative" }}>
                {props.seoData.slider_img_text && window.innerWidth <= 992 && (
                  <div
                    className={`fade-in ${fadeIn ? "active" : ""} cus-position`}
                  >
                    <div className="text-white cus-banner-title-bg-color">
                      <div>
                        {ReactHtmlParser(props.seoData.slider_img_text)}
                      </div>
                    </div>
                  </div>
                )}
                {isMobile && (
                  <ProgressiveImage
                    src={mobResult()}
                    placeholder={placeholderSrc}
                  >
                    {({ src = mobResult() }, loading) => {
                      return (
                        <picture className="bg-gradient" title={titleImg}>
                          <source
                            className={`w-100 h-100 banner-xs mx-0  mt-0 d-block d-md-none d-lg-none d-xl-none ${
                              loading ? " loading" : " loaded"
                            }`}
                            srcSet={src.webpImage}
                            type="image/webp"
                            alt="Banner_Image"
                            loading="lazy"
                            title={titleImg}
                          ></source>
                          <source
                            className={`w-100 h-100 banner-xs mx-0  mt-0 d-block d-md-none d-lg-none d-xl-none ${
                              loading ? " loading" : " loaded"
                            }`}
                            srcSet={src.jpgImage}
                            type="image/jpg"
                            alt="Banner_Image"
                            loading="lazy"
                            title={titleImg}
                          ></source>
                          <img
                            className={`w-100 h-100 banner-xs mx-0  mt-0 d-block d-md-none d-lg-none d-xl-none ${
                              loading ? " loading" : " loaded"
                            }`}
                            src={src.jpgImage}
                            alt="Banner_Image"
                            type="image/jpg"
                            loading="lazy"
                            title={titleImg}
                          />
                        </picture>
                      );
                    }}
                  </ProgressiveImage>
                )}
              </div>
            ) : (
              isMobile && (
                <div className="BanDetImg">
                  <div className="w-100">
                    <img
                      className="W100 card-img-top"
                      src={Default_Mob}
                      title="No Image"
                      alt="No Img"
                    />
                  </div>
                </div>
              )
            )}
            <div className="inner-text-overlay"></div>
            {showMeta && (
              <MetaTags>
                <title>{ReactHtmlParser(props.seoData.meta_title)}</title>
                <meta
                  name="description"
                  content={ReactHtmlParser(props.seoData.meta_desc)}
                />
                <meta
                  property="og:title"
                  content={ReactHtmlParser(props.seoData.meta_title)}
                />
                <meta
                  property="og:description"
                  content={ReactHtmlParser(props.seoData.meta_desc)}
                />
                <meta
                  name="twitter:title"
                  content={ReactHtmlParser(props.seoData.meta_title)}
                />

                <meta
                  name="twitter:description"
                  content={ReactHtmlParser(props.seoData.meta_desc)}
                />

                <meta property="og:type" content="article" />
                <meta property="og:url" content={CurrentWebUrl} />

                {(props.seoData.og_image || props.seoData.slider_img) && (
                  <meta
                    property="og:image"
                    content={
                      baseUrl +
                      "/gallery-images/properties/" +
                      ReactHtmlParser(
                        props.seoData.og_image || props.seoData.slider_img
                      )
                    }
                  />
                )}

                {(props.seoData.og_image || props.seoData.slider_img) && (
                  <meta
                    name="twitter:image"
                    content={
                      baseUrl +
                      "/gallery-images/properties/" +
                      ReactHtmlParser(
                        props.seoData.og_image || props.seoData.slider_img
                      )
                    }
                  />
                )}
                <meta
                  property="og:site_name"
                  content={propertyData?.property_name}
                />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content={CurrentWebUrl} />
                <link rel="canonical" href={CurrentWebUrl} />
              </MetaTags>
            )}
          </div>

          <>
            {themeData.is_marketing === "0" ? (
              <div id="showMe">
                {themeData.theme === "yosemitewestgate" ? (
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <MemoizedCheckAvailabilityYosemite />
                  </React.Suspense>
                ) : null}
              </div>
            ) : (
              <div id="showMe">
                {themeData.theme === "hotelcurrent" ? (
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <MemoizedCheckAvailabilityHotelCurrent />
                  </React.Suspense>
                ) : themeData.theme === "hotellela" ? (
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <MemoizedCheckAvailabilityHotelLela />
                  </React.Suspense>
                ) : themeData.theme === "santacruzcomfortinn" ? (
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <MemoizedCheckAvailabilityComfortInnSC />
                  </React.Suspense>
                ) : themeData.theme === "innatsaratoga" ? (
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <MemoizedCheckAvailabilityInnatsaratoga />
                  </React.Suspense>
                ) : null}
              </div>
            )}
          </>
        </div>
      )}

      {window.location.href.includes("bookingConfirmation") && (
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 binStepBarWrap">
          <div className="tab">1. Cart</div>
          <div className="tab">2. Guest Information</div>
          <div className="tab active">3. Booking Confirmation</div>
        </div>
      )}
      <div className="text-center">
        {document.querySelector(".oops") ? (
          <>
            <h1 className="about primary-title underline-btm">
              oops page not found.
            </h1>
            <div className="h1Seperator text-center m-auto"></div>
          </>
        ) : (
          <>
            {props.RoomDetailsData && props.RoomDetailsData.room_name ? (
              <>
                <div className={`fade-in ${fadeIn ? "active" : ""}`}>
                  <h1
                    className="about primary-title underline-btm container px-md-0"
                    id="info1"
                  >
                    {props.RoomDetailsData.room_name}
                  </h1>
                  <div className="h1Seperator text-center mx-auto"></div>

                  {props?.seoData?.property_content && (
                    <div className="seo-content container">
                      {ReactHtmlParser(props?.seoData?.property_content)}
                    </div>
                  )}
                </div>
              </>
            ) : (
              props.seoData.h1 && (
                <>
                  <div className={`fade-in ${fadeIn ? "active" : ""}`}>
                    <h1
                      className="about primary-title underline-btm container px-md-0"
                      id="info1"
                    >
                      {props.seoData.h1}
                    </h1>
                    <div className="h1Seperator text-center mx-auto"></div>
                    {props?.seoData?.property_content && (
                      <div className="seo-content container">
                        {ReactHtmlParser(props?.seoData?.property_content)}
                      </div>
                    )}
                  </div>
                </>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BannerContainer;
