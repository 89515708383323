// method 1

let Components = {};
Components["yosemitewestgate"] = require("./yosemitewestgate").default;
Components["innatsaratoga"] = require("./innatsaratoga").default;
Components["hotellela"] = require("./hotellela").default;
Components["hotelcurrent"] = require("./hotelcurrent").default;
Components["santacruzcomfortinn"] = require("./santacruzcomfortinn").default;
export default Components;

// method 2

// const Components =(theme)=>{
//     let componentToRender = null;
//     switch(theme){
//         case theme === "yosemitewestgate":
//             componentToRender = require("./yosemitewestgate").default;
//             break;
//         case theme === "innatsaratoga":
//             componentToRender = require("./innatsaratoga").default;
//             break;
//         case theme === "hotellela":
//             componentToRender = require("./hotellela").default;
//             break;
//         case theme === "hotelcurrent":
//             componentToRender = require("./hotelcurrent").default;
//             break;
//         default:
//             componentToRender = require("./yosemitewestgate").default;
//             break;
// }

// return componentToRender;
// }

// export default Components;

// method 3

// const Components = (theme) => {
//   let componentToRender;

//   if (theme === "yosemitewestgate") {
//     componentToRender = require("./yosemitewestgate").default;
//   }
//   if (theme === "innatsaratoga") {
//     componentToRender = require("./innatsaratoga").default;
//   }
//   if (theme === "hotellela") {
//     componentToRender = require("./hotellela").default;
//   }
//   if (theme === "hotelcurrent") {
//     componentToRender = require("./hotelcurrent").default;
//   }

//   return componentToRender;
// };

// export default Components;
