import {
  getSixPlusBookingUrl,
  getSeoDescriptionUrl,
  getYesomiteDescriptionUrl,
  getSubscribeEmailUrl,
  homePageMessageUrl,
  getPropAmenitiesUrl,
  getPropEventContactUrl,
  getPropNewsUrl,
  getPropReviewLikeUrl,
  getPropBlogPageUrl,
  getPropNewsDetailsUrl,
  getPropJobssUrl,
  getPropJobDetailssUrl,
  getPropLostAndFoundUrl,
  getPropLostFoundDetailsUrl,
  getPropPersonalInfoReqUrl,
  getPropCleanProtocolsUrl,
  getPropCityeUrl,
  getPropLocalServicesUrl,
  getPropParkingUrl,
  getPropDestinationAttractionUrl,
  getPropJobFormPostUrl,
  getPropFaqsUrl,
  getPropCollegeAndUniversityUrl,
  getPropContactUsUrl,
  getPropRoomsUrl,
  getPropAttractionDetailssUrl,
  getPropTopReasonToStayUrl,
  getPropAdaAccesibilityUrl,
  getPropEventsUrl,
  customeApiPmsPageUrl,
  getPropReviewComponentUrl,
  getPropReviewLikeDislikeUrl,
  getPropFriendPageUrl,
  getPropFitnessUrl,
  getPropEventsDetailsUrl,
  getPropVideoUrl,
  getPropPackageFormUrl,
  getPropStateUrl,
  getThingToDoDetailsUrl,
  getPropDirectionMapUrl,
  getPropGuestRoomsUrl,
  getPropCreditCardDataUrl,
  getPropTransportUrl,
  getPropRoomDetailsUrl,
  getPropBlogDetailsUrl,
  getPropPackagesPageUrl,
  getPropPackagesDetailsPageUrl,
  getPropFriendDetailsPageUrl,
  getPropVirtualToursUrl,
  getPropEntertainmentUrl,
  getPropPetsUrl,
  getPropGalleryUrl,
  getPropReviewDetailsComponentUrl,
  getPropCountry,
  getPropWeatherUrl,
  getPropAddReviewsComponentUrl,
  getPropsParkingPageUrl,
  getPropFitnessDataUrl,
  getPropPoolPageUrl,
  getPropArtCultureUrl,
  getPropBookUrl,
  getBookingData,
  resendReservationMail,
  addCalendarURL,
  editReservationURL,
  getEditGuestNameURL,
  getEmailToGuestURL,
  getCurrencyConverterURL,
  getSpecialRequestURL,
  getDietaryRestrictionURL,
  getPropSiteMapUrl,
  getSubmitEstimatedArrivalURL,
  getCurrencyUrl,
  getPropertyURL,
  getPropUnSubscribedUrl,
  getPropHomePopUrl,
  getPropRfpUrl,
  getPropRfpUrlHotelCurrent,
} from "../Configuration/config_url";
import {
  postAPICall,
  postApiCallPopupHomepage,
  postApiCallReviews,
} from "./axiosMethodCalls";
import axios from "axios";

export const getSeoDescriptionData = async (pageId, property_id) => {
  try {
    const response = await postAPICall(getSeoDescriptionUrl, {
      pageid: pageId,
      isCheck: true,
      property_id: property_id,
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

// Axios Get Call - Get all yesomite Data.
export const getYesomiteData = async (propertyId) => {
  console.log("999", propertyId);
  try {
    const response = await postAPICall(getYesomiteDescriptionUrl, {
      property_id: propertyId,
    });
    console.log("102", response);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropMessageData = async (data) => {
  try {
    const response = await postAPICall(homePageMessageUrl, {
      message_url: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropHomePopData = async (data) => {
  console.log("data popup-->>>>", data);
  try {
    const response = await postApiCallPopupHomepage(getPropHomePopUrl, {
      page_sort_type: data.page_sort_type,
      keenpropertyid: data.keenpropertyid,
      property_id: data.property_id,
    });
    console.log("api of popup", response);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getSubscribeEmailData = async (data) => {
  try {
    const response = await postAPICall(getSubscribeEmailUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropPetsData = async (data) => {
  try {
    const response = await postAPICall(getPropPetsUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropSiteMapData = async (data) => {
  try {
    const response = await postAPICall(getPropSiteMapUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropWeatherPageData = async (data) => {
  try {
    const response = await postAPICall(getPropWeatherUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropFitnessData = async (data) => {
  try {
    const response = await postAPICall(getPropFitnessUrl, {});
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropAmenities = async () => {
  try {
    const response = await postAPICall(getPropAmenitiesUrl);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropArtCultureData = async () => {
  try {
    const response = await postAPICall(getPropArtCultureUrl);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropNewsData = async () => {
  try {
    const response = await postAPICall(getPropNewsUrl);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropNewsDetailsData = async (data) => {
  try {
    const response = await postAPICall(getPropNewsDetailsUrl, {
      id: data.id,
      property_id: 265,
    });

    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropBlogData = async (data) => {
  try {
    const response = await postAPICall(getPropBlogPageUrl, {
      blog_type: data.blog_type,
      blog_category: data.blog_category,
      blog_date: data.blog_date,
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

export const getCustomApiPageData = async (data) => {
  try {
    const response = await postAPICall(customeApiPmsPageUrl, {
      page_url: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

// export const getPropBlogDetails = async (data) => {
//   try {
//     const response = await postAPICall(getPropBlogDetailsUrl, {
//       page_url: data.page_url,
//     });
//     return response;
//   } catch (error) {
//     return error.response;
//   }
// };

export const getPropBlogDetails = async (data) => {
  try {
    const response = await postAPICall(getPropBlogDetailsUrl, {
      page_url: data.page_url,
      blog_tag: data.blog_tag,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropUnSubscribed = async (data) => {
  try {
    const response = await postAPICall(getPropUnSubscribedUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropJobsData = async () => {
  try {
    const response = await postAPICall(getPropJobssUrl);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropJobDetailsData = async (data) => {
  try {
    const response = await postAPICall(
      getPropJobDetailssUrl,
      { jid: data.jid },
      { jtitle: data.jtitle },
      data
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPackagesInfoData = async (data) => {
  try {
    const response = await postAPICall(getPropPackagesPageUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPackagesDetailsInfoData = async (data) => {
  try {
    const response = await postAPICall(getPropPackagesDetailsPageUrl, {
      packages_type_url: data.subdomain,
      form_data: data.form_data,
      property_id: 265,
    });

    return response;
  } catch (error) {
    return error.response;
  }
};
export const getEventContactDetailsFormData = async (data) => {
  try {
    const response = await postAPICall(getPropEventContactUrl, {
      form_data: data,
    });
    return response;
  } catch (error) {
    return error.reponse;
  }
};

export const getPackagesDetailsCityData = async (data) => {
  try {
    const response = await postAPICall(getPropCityeUrl, { state: data });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPackagesDetailsStateData = async (data) => {
  try {
    const response = await postAPICall(getPropStateUrl, { country: "USA" });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPackagesDetailsFormData = async (data) => {
  try {
    const response = await postAPICall(getPropPackageFormUrl, {
      form_data: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropJobFormPostData = async (data) => {
  try {
    const response = await postAPICall(
      getPropJobFormPostUrl,
      data,
      { jid: data.jid },
      { jtitle: data.jtitle }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropFaqData = async () => {
  try {
    const response = await postAPICall(getPropFaqsUrl);
    const newResponse = null;
    if (response == null || response == "") {
      return null;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }
};

export const getPropCollegeAndUniversityData = async () => {
  try {
    const response = await postAPICall(getPropCollegeAndUniversityUrl);
    return response;
  } catch (error) {
    return error.reponse;
  }
};

export const getPropReviewData = async (data) => {
  try {
    const response = await postApiCallReviews(getPropReviewComponentUrl, {
      page_no: data.page_no,
      ota_id: data.ota_id,
      trip_type: data.trip_type,
      traveled_with: data.traveled_with,
      traveled_date: data.traveled_date,
      ota_rating: data.ota_rating,
      property_id: data.property_id,

      review_id: data.review_id,
      status: data.status,
    });

    console.log(response);

    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropReviewLikeData = async (review_id, status, property_id) => {
  try {
    const response = await postApiCallReviews(getPropReviewLikeDislikeUrl, {
      review_id,
      status,
      property_id,
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropReviewDetailsData = async (data) => {
  console.log("Data inside getPropReviewDetailsData==>", data);
  try {
    const response = await postApiCallReviews(
      getPropReviewDetailsComponentUrl,
      {
        review_title: data.ReviewTitle,
        review_id: data.review_id,
        property_id: data.property_id,
      }
    );

    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropAddReviewData = async (data) => {
  try {
    const response = await postApiCallReviews(
      getPropAddReviewsComponentUrl,
      data
    );
    console.log(response);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropCountryviewData = async (data) => {
  try {
    const response = await postAPICall(getPropCountry);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropEventsData = async (data) => {
  console.log("hellow event-->", data);
  try {
    const response = await postAPICall(getPropEventsUrl, {
      eventRangeSelect: data,
    });
    console.log("response event-->", response);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropVideoData = async () => {
  try {
    const response = await postAPICall(getPropVideoUrl);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropEntertainmentData = async () => {
  try {
    const response = await postAPICall(getPropEntertainmentUrl);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropLocalServicesData = async () => {
  try {
    const response = await postAPICall(getPropLocalServicesUrl);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropTransportData = async () => {
  try {
    const response = await postAPICall(getPropTransportUrl);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropVirtualToursData = async () => {
  try {
    const response = await postAPICall(getPropVirtualToursUrl);
    return response;
  } catch (error) {
    return error.response;
  }
};

// export const getPropVirtualToursData = async () => {
//   try {
//     const response = await postAPICall(getPropVirtualToursUrl);
//      return response; }
//      catch (error) { return error.response;
//     }
//    };

export const getGalleryData = async (data) => {
  try {
    const response = await postAPICall(getPropGalleryUrl, data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropPoolData = async () => {
  try {
    const response = await postAPICall(getPropPoolPageUrl);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropParkingData = async () => {
  try {
    const response = await postAPICall(getPropParkingUrl);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropCreditCardData = async () => {
  try {
    const response = await postAPICall(getPropCreditCardDataUrl);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropEventsDetailsData = async (data) => {
  try {
    const response = await postAPICall(getPropEventsDetailsUrl, {
      event_name: data.event_name,
      property_id: 265,
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropTopStayData = async () => {
  try {
    const response = await postAPICall(getPropTopReasonToStayUrl);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropFriendPageData = async () => {
  try {
    const response = await postAPICall(getPropFriendPageUrl);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropFriendDetailsPageData = async (data) => {
  try {
    const response = await postAPICall(getPropFriendDetailsPageUrl, {
      friend_name: data.sub_url,
      property_id: 265,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropAdaAccesibilityData = async () => {
  try {
    const response = await postAPICall(getPropAdaAccesibilityUrl);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getContactform = async (data) => {
  try {
    const response = await postAPICall(getPropContactUsUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getRfpform = async (data) => {
  try {
    const response = await postAPICall(getPropRfpUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getRfpformHotelCurrent = async (data) => {
  try {
    const response = await postAPICall(getPropRfpUrlHotelCurrent, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropLostFoundData = async () => {
  try {
    const response = await postAPICall(getPropLostAndFoundUrl);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
export const getSixPlusBookingData = async (data) => {
  try {
    const response = await postAPICall(getSixPlusBookingUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropLostFoundDetailsData = async (data) => {
  try {
    const response = await postAPICall(getPropLostFoundDetailsUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropPersonalInfoReqData = async (data) => {
  try {
    const response = await postAPICall(getPropPersonalInfoReqUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropCleanProtocolData = async () => {
  try {
    const response = await postAPICall(getPropCleanProtocolsUrl);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropDestinationAttractionData = async (data) => {
  try {
    const response = await postAPICall(getPropDestinationAttractionUrl, data);
    console.log(response);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropAttractionsDetailsData = async (data) => {
  try {
    const response = await postAPICall(getPropAttractionDetailssUrl, data, {
      attraction_url: data.attraction_url,
    });

    console.log(response);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getThingToDoData = async (data) => {
  try {
    const response = await postAPICall(getThingToDoDetailsUrl, data);
    console.log("data-utilities-ThingsToDoPage", response);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropDirectionMap = async () => {
  try {
    const response = await postAPICall(getPropDirectionMapUrl);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getGuestRoomsData = async (data) => {
  try {
    const response = await postAPICall(getPropGuestRoomsUrl, data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getPropRoomsDetailsData = async (data) => {
  try {
    const response = await postAPICall(getPropRoomDetailsUrl, data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

//Reservation Page START

function in_array(array, id) {
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== undefined) {
      if (array[i].name === id) {
        return array[i].value;
      }
    }
  }
  return "0";
}

export const getRoomsForReservation = async (roomObject) => {
  try {
    const response = await axios.post(getPropRoomsUrl, roomObject, {
      headers: { "Content-Type": "text/plain" },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const submitReservationData = async (data) => {
  try {
    const response = await postAPICall(getPropBookUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getQueryParams = async (data) => {
  try {
    if (data) {
      let roomObject = {};
      roomObject["room_check_in"] =
        data === undefined ? "2022-03-28" : data.room_check_in;
      roomObject["room_check_out"] =
        data === undefined ? "2022-03-29" : data.room_check_out;
      roomObject["promo_code"] = data === undefined ? "" : data.promo_code;
      roomObject["rooms"] = data === undefined ? "1" : data.no_of_rooms;

      for (let i = 0; i < roomObject["rooms"]; i++) {
        if (data.adults[i].name === "adults" + i) {
          let str = data.adults[i].value;
          let key = 0;
          if (data.childs) {
            for (let c = 0; c < data.childs.length; c++) {
              if (typeof data.childs[c] !== "undefined") {
                if (data.childs[c].name == "child" + i) {
                  key = c;
                }
              }
            }

            if (typeof data.childs[key] !== "undefined") {
              for (let c = 0; c < data.childs[key].value; c++) {
                let childVal = in_array(
                  data.childsAge,
                  "childage_" + i + "_" + c
                );
                if (childVal !== "0") {
                  str = str + "-" + childVal;
                }
              }
            }
          }
          roomObject["room" + (i + 1)] = str;
        }
      }
      let q = "";
      for (const obj in roomObject) {
        q += obj + "=" + roomObject[obj] + "&";
      }
      return q.slice(0, -1);
    }
  } catch (error) {
    return error.response;
  }
};

export const getReservationData = async (data) => {
  try {
    const response = await postAPICall(getBookingData, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const resendReservationMailFn = async (data) => {
  try {
    const response = await postAPICall(resendReservationMail, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const addReservationToCalendar = async (data) => {
  try {
    const response = await postAPICall(addCalendarURL, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const editReservation = async (data) => {
  try {
    const response = await postAPICall(editReservationURL, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const submitEditGuestName = async (data) => {
  try {
    const response = await postAPICall(getEditGuestNameURL, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const submitEmailToGuest = async (data) => {
  try {
    const response = await postAPICall(getEmailToGuestURL, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const submitCurrencyConverter = async (data) => {
  try {
    const response = await postAPICall(getCurrencyConverterURL, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const submitSpecialRequest = async (data) => {
  try {
    const response = await postAPICall(getSpecialRequestURL, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const submitDietaryRestriction = async (data) => {
  try {
    const response = await postAPICall(getDietaryRestrictionURL, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const submitEstimatedArrival = async (data) => {
  try {
    const response = await postAPICall(getSubmitEstimatedArrivalURL, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateCurrecny = async (data) => {
  try {
    const response = await postAPICall(getCurrencyUrl, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPropertyData = async (data) => {
  try {
    const response = await postAPICall(getPropertyURL, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

//Reservation Page END
