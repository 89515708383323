import React, { useState, useEffect, lazy } from "react";
import { useLocation, withRouter } from "react-router-dom";
import ReservationBotton from "../Home/sections/ReservationButton";
import "./sideApp.scss";
import { FaArrowUp } from "react-icons/fa";
import {
  getSideMenuSelector,
  propertyDataSelector,
} from "../../../Recoil/themeModule";
import { useRecoilState, useRecoilValue } from "recoil";
import StartMenuComponent from "./StartMenu.js";
import { BiLogoFacebook } from "react-icons/bi";
import { AiOutlineInstagram } from "react-icons/ai";

if (
  window.location.href.includes("hotellela") ||
  window.location.href.includes("localhost")
) {
  var SlideMenuComponent = lazy(() =>
    import("../../Hotellela/slidemenu/slidemenu")
  );
}
const Header = ({ history }) => {
  const [showSideMenu, setShowSideMenu] = useRecoilState(getSideMenuSelector);
  const propertyData = useRecoilValue(propertyDataSelector);
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", myScrollFunc);
  }, []);
  var myScrollFunc = function () {
    var y = window.scrollY;
    if (y >= 500) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const element = document.getElementsByClassName("custom_IH_wrapper")[0];
    if (element) {
      if (width >= 2090) {
        element.classList.remove("container");
      } else {
        element.classList.add("container");
      }
    }
  }, [width]);
  useEffect(() => {
    const element = document.getElementsByClassName("custom_IH_wrapper")[0];
    if (element) {
      if (width >= 2090) {
        element.classList.remove("container");
      } else {
        element.classList.add("container");
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowSideMenu(true);
    }, 2000);
  }, []);

  if (location.pathname === "/reservations") {
    return null;
  }

  return showSideMenu ? (
    <div className="Main-header-links">
      <header className="d-flex align-items-center">
        {/* navbar menus  */}
        <StartMenuComponent />
        {/* navbar menus  */}

        {/* Reservation button  */}
        <div className="container container-mob-view px-0 custom_IH_wrapper">
          <div className="inner-header">
            <div className="menuReserDiv">
              <div className="reservDiv">
                <div className="reservationBtn">
                  <ReservationBotton />
                </div>
              </div>
              <div className="humburgMenuDiv">
                <SlideMenuComponent />
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              data-aos-duration="1000"
              className="scrollBtn"
            >
              {visible && (
                <button
                  className="scrolBtnStyle"
                  type="button"
                  id="scrollTop"
                  title="Scroll To Top"
                >
                  scrollBtn
                  <FaArrowUp onClick={scrollToTop} />
                </button>
              )}
            </div>
          </div>
        </div>
        {/* Reservation button  */}

        {/* Social icons  */}
        <div className="socialIconsStye mt-3 mt-md-1 mb-lg-0 pt-lg-0 navSocial d-lg-flex">
          <div className="social-container container-fluid px-0 px-md-2">
            <ul className="list-unstyled">
              {propertyData.facebook !== "" ? (
                <li>
                  <a
                    target="_blank"
                    href={`${propertyData.facebook}`}
                    className="mx-1"
                    rel="noreferrer"
                  >
                    <span className="empty-div">No Text</span>
                    <BiLogoFacebook className="icon" />
                  </a>
                </li>
              ) : (
                <li className="d-none"></li>
              )}

              {propertyData.instagram_url !== "" ? (
                <li>
                  <a
                    target="_blank"
                    href={`${propertyData.instagram_url}`}
                    className="mx-1"
                    rel="noreferrer"
                  >
                    <span className="empty-div">No Text</span>
                    <AiOutlineInstagram className="icon" />
                  </a>
                </li>
              ) : (
                <li className="d-none"></li>
              )}
            </ul>
          </div>
        </div>
        {/* Social icons  */}
      </header>
    </div>
  ) : (
    <div></div>
  );
};

export default withRouter(Header);
