import React, { useState, useEffect, lazy, Suspense } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import MetaTags from "react-meta-tags";
import ReactHtmlParser from "react-html-parser";
import "./App.css";
import {
  loaderImageSelector,
  propertyDataSelector,
  themeSelector,
  getSeoDataSelector,
} from "./Recoil/themeModule";

const AppRouting = lazy(() => import("./Approuting"));
const MainFooter = lazy(() => import("./E/InnAtSaratoga/Footer/MainFooter"));
const MainHeader = lazy(() => import("./E/InnAtSaratoga/Header/MainHeader"));

export default function InnAtSaratogaHomepage(props) {
  const [themeData, setThemeData] = useRecoilState(themeSelector);
  const [seoData, setPropertySeodata] = useRecoilState(getSeoDataSelector);
  const [propertyData, setPropertydata] = useRecoilState(propertyDataSelector);
  const { url: baseUrl } = useRecoilValue(themeSelector);
  const imageLoader = useRecoilValue(loaderImageSelector);
  const [isHide, setIsHide] = useState(true);
  setTimeout(() => setIsHide(false), 2000);

  const [iconPaths, setIconPaths] = useState([
    "/appleTouch/72X72.png",
    "/appleTouch/114X114.png",
    "/appleTouch/144X144.png",
    "/appleTouch/200X200.png",
  ]);

  useEffect(() => {
    // Function to update the icon paths
    const updateIconPaths = () => {
      // Modify the iconPaths state as needed
      setIconPaths([
        "/appleTouch/72X72.png",
        "/appleTouch/114X114.png",
        "/appleTouch/144X144.png",
        "/appleTouch/200X200.png",
      ]);
    };

    // Call the function to update the icon paths
    updateIconPaths();
  }, []);

  const [isFooterLoaded, setFooterLoaded] = useState(false);

  useEffect(() => {
    // Simulate an asynchronous operation to load the header
    setTimeout(() => {
      setFooterLoaded(true);
    }, 1200); // Adjust the timeout value as needed
  }, []);

  document.getElementById("goUp") &&
    document.getElementById("goUp").addEventListener("click", () => {
      const element = document.getElementById("checkAvailability");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    });

  return (
    <Suspense fallback={<div></div>}>
      <div className="App">
        <MetaTags>
          <title>
            {seoData?.meta_title ? ReactHtmlParser(seoData?.meta_title) : ""}
          </title>
          <meta
            name="description"
            content={ReactHtmlParser(seoData.meta_desc)}
          />
          <meta
            property="og:title"
            content={ReactHtmlParser(seoData?.meta_title)}
          />
          <meta
            property="og:description"
            content={ReactHtmlParser(seoData?.meta_desc)}
          />
          <meta name="theme-color" content="#000000" />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={themeData.url} />
          {(seoData.og_image || seoData.slider_img) && (
            <meta
              property="og:image"
              content={
                baseUrl +
                "/gallery-images/properties/" +
                ReactHtmlParser(seoData.og_image || seoData.slider_img)
              }
            />
          )}

          {(seoData.og_image || seoData.slider_img) && (
            <meta
              name="twitter:image"
              content={
                baseUrl +
                "/gallery-images/properties/" +
                ReactHtmlParser(seoData.og_image || seoData.slider_img)
              }
            />
          )}
          <meta property="og:site_name" content={propertyData?.property_name} />
          <meta
            name="twitter:title"
            content={ReactHtmlParser(seoData?.meta_title)}
          />

          <meta
            name="twitter:description"
            content={ReactHtmlParser(seoData.meta_desc)}
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={themeData.url} />
          <link rel="canonical" href={themeData.url} />
        </MetaTags>

        {!imageLoader ? (
          <div className="container-fluid px-0">
            <MainHeader />
            <div style={{ minHeight: "100vh" }}>
              <AppRouting propertyData={props.propertyData} />
            </div>
            <div>
              <>
                {iconPaths.map((path, index) => (
                  <link key={index} rel="apple-touch-icon" href={path} />
                ))}
              </>
            </div>
            <>
              {isFooterLoaded && (
                <MainFooter
                  className="footer-test"
                  propertyData={props.propertyData}
                />
              )}
              <div
                className="fixedReservationBtn reservationDiv d-xl-none d-lg-none d-md-block d-sm-block d-xs-block"
                id="goUp"
              >
                <a>Reservations</a>
              </div>
              <label
                htmlFor=" mini-popup-resolution"
                id=" mini-popup-resolution"
                className="sr-only d-none"
              >
                hidden label
              </label>
            </>
          </div>
        ) : null}
        <label
          htmlFor=" mini-popup-resolution"
          id=" mini-popup-resolution"
          className="sr-only d-none"
        >
          hidden label
        </label>
      </div>
    </Suspense>
  );
}
