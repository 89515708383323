import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import headerLogo from "../assets/Icons/header-logo.png";
import "./StartMenu.scss";
import HotellelaIcon from "../../Hotellela/assets/Icons/HotelLelaWhite.svg";
import { propertyDataSelector } from "../../../Recoil/themeModule";
import { useRecoilValue } from "recoil";
import { FaArrowUp } from "react-icons/fa";
import { BiSolidChevronDown } from "react-icons/bi";

function StartMenuComponent() {
  const [isSticky, setIsSticky] = useState(false);
  const [visible, setVisible] = useState(false);
  const propertyData = useRecoilValue(propertyDataSelector);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [show, setShow] = useState({
    gallery: false,
    events: false,
    destinations: false,
  });

  useEffect(() => {
    window.addEventListener("scroll", myScrollFunc);
  }, []);
  var myScrollFunc = function () {
    var y = window.scrollY;
    if (y >= 500) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setShow({
      gallery: false,
      events: false,
      destinations: false,
    });
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown, so close it
        setShow({
          gallery: false,
          events: false,
          destinations: false,
        });
      }
    };

    if (show.gallery || show.events || show.destinations) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [show.gallery, show.events, show.destinations]);

  return (
    <div className={`SideAppStyle StartMenuStyle`}>
      {window.innerWidth > 1199 ? (
        <div className="desk-menu">
          <div className="grid-container-list">
            <ul className="grid-list d-flex justify-content-around align-items-center mb-0">
              <li>
                <Link to="/">
                  <img height="70" src={headerLogo} alt="headerLogo" />
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className={location.pathname === "/" ? "active" : ""}
                >
                  HOME
                </Link>
              </li>

              {propertyData.guest_room_status === "1" && (
                <li>
                  <Link
                    to="/guestrooms"
                    className={
                      location.pathname === "/guestrooms" ||
                      location.pathname ===
                        `/guestrooms/${window.location.href.split("/")[4]}`
                        ? "active"
                        : ""
                    }
                  >
                    ROOMS
                  </Link>
                </li>
              )}
              {propertyData.package_status === "1" && (
                <li>
                  <Link
                    to="/packages"
                    className={
                      location.pathname === "/packages" ||
                      location.pathname ===
                        `/packages/${window.location.href.split("/")[4]}`
                        ? "active"
                        : ""
                    }
                  >
                    OFFERS
                  </Link>
                </li>
              )}

              {propertyData.panographic_status === "1" ||
              propertyData.video_status === "1" ? (
                <li>
                  <div className="dropdown" ref={dropdownRef}>
                    <div
                      className={
                        location.pathname === "/gallery" ||
                        location.pathname === "/3d-virtual-tours" ||
                        location.pathname === "/videos"
                          ? "active dropdown-trigger"
                          : "dropdown-trigger"
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setShow({
                          gallery: !show.gallery,
                          events: false,
                          destinations: false,
                        });
                      }}
                    >
                      GALLERY
                      <BiSolidChevronDown size={20} />
                    </div>{" "}
                    {show.gallery && (
                      <div className="dropdown-content gallery-content">
                        {propertyData.gallery_status === "1" && (
                          <Link
                            to="/gallery"
                            className={
                              location.pathname === "/gallery"
                                ? "active dropbtn"
                                : ""
                            }
                          >
                            GALLERY
                          </Link>
                        )}
                        {propertyData.panographic_status === "1" && (
                          <Link
                            to="/3d-virtual-tours"
                            className={
                              location.pathname === "/3d-virtual-tours"
                                ? "active dropbtn"
                                : ""
                            }
                          >
                            3D VIRTUAL TOURS
                          </Link>
                        )}
                        {propertyData.video_status === "1" && (
                          <Link
                            to="/videos"
                            className={
                              location.pathname === "/videos"
                                ? "active dropbtn"
                                : ""
                            }
                          >
                            VIDEOS
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </li>
              ) : propertyData.gallery_status === "1" ? (
                <li>
                  <Link
                    to="/gallery"
                    className={location.pathname === "/gallery" ? "active" : ""}
                  >
                    GALLERY
                  </Link>
                </li>
              ) : null}

              <li>
                <Link
                  to="/amenities"
                  className={location.pathname === "/amenities" ? "active" : ""}
                >
                  AMENITIES
                </Link>
              </li>

              <li>
                <Link
                  to="/dining"
                  className={location.pathname === "/dining" ? "active" : ""}
                >
                  DINING
                </Link>
              </li>

              <li>
                <Link
                  to="/events"
                  className={location.pathname === "/events" ? "active" : ""}
                >
                  EVENTS
                </Link>
              </li>

              <li>
                <Link
                  className={
                    location.pathname === `/${propertyData?.city}-attractions`
                      ? "active"
                      : ""
                  }
                  to={`/${propertyData?.city}-attractions`}
                >
                  LOCAL AREA
                </Link>
              </li>

              {/* {(propertyData.attraction_status === "1" ||
                propertyData.thingstodoStatus === "1" ||
                propertyData.your_guide_onoff_status === "1" ||
                propertyData.weather_status === "1") && (
                <li>
                  <div className="dropdown" ref={dropdownRef}>
                    {" "}
                    <div
                      className={
                        location.pathname ===
                          `/${propertyData?.city}-attractions` ||
                        location.pathname === "/things-to-do" ||
                        location.pathname === "/guided-tours" ||
                        location.pathname === "/weather"
                          ? "active dropdown-trigger"
                          : "dropdown-trigger"
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setShow({
                          gallery: false,
                          events: false,
                          destinations: !show.destinations,
                        });
                      }}
                    >
                      DESTINATION
                      <BiSolidChevronDown size={20} />
                    </div>{" "}
                    {show.destinations && (
                      <div className="dropdown-content">
                        {propertyData.attraction_status === "1" && (
                          <Link
                            className={
                              location.pathname ===
                              `/${propertyData?.city}-attractions`
                                ? "active dropbtn"
                                : ""
                            }
                            to={`/${propertyData?.city}-attractions`}
                          >
                            LOCAL AREA
                          </Link>
                        )}

                        {propertyData.thingstodoStatus === "1" && (
                          <Link
                            to="/things-to-do"
                            className={
                              location.pathname === "/things-to-do"
                                ? "active dropbtn"
                                : ""
                            }
                          >
                            THINGS TO DO
                          </Link>
                        )}

                        {propertyData.your_guide_onoff_status === "1" && (
                          <Link
                            to="/guided-tours"
                            className={
                              location.pathname === "/guided-tours"
                                ? "active dropbtn"
                                : ""
                            }
                          >
                            GUIDED TOURS
                          </Link>
                        )}
                        {propertyData.weather_status === "1" && (
                          <Link
                            to="/weather"
                            className={
                              location.pathname === "/weather"
                                ? "active dropbtn"
                                : ""
                            }
                          >
                            WEATHER
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </li>
              )} */}
            </ul>
          </div>
        </div>
      ) : (
        <div className="header-mob">
          <div className="logo-mob">
            <Link to="/">
              <img src={HotellelaIcon} alt="HotelLelaIcon" />
            </Link>
          </div>
          <div className="menu-mob"></div>
        </div>
      )}

      <div data-aos="zoom-in-up" data-aos-duration="1000" className="scrollBtn">
        {visible && (
          <button className="scrolBtnStyle" type="button" id="scrollTop">
            scrollBtn
            <FaArrowUp onClick={scrollToTop} />
          </button>
        )}
      </div>
    </div>
  );
}

export default StartMenuComponent;
