import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { propertyDataSelector } from "./Recoil/themeModule";

const AnalyticsComponentBody = () => {
  const [propertyData] = useRecoilState(propertyDataSelector);

  useEffect(() => {
    const loadNoScriptTag = () => {
      if (!propertyData) {
        console.error("propertyData is undefined or null");
        return;
      }
      const noscriptTag = document.createElement("noscript");
      setTimeout(() => {
        noscriptTag.setAttribute("defer", true);
        const noscriptInnerHtml = propertyData?.gtm_body_code;
        noscriptTag.innerHTML = noscriptInnerHtml;
        document.body.insertBefore(noscriptTag, document.body.firstChild);
      }, 5000);

      // return () => document.body.removeChild(noscriptTag);
    };

    const cleanup = loadNoScriptTag();
    return cleanup;
  }, [propertyData]); // Dependency only on propertyData now

  return null;
};

export default AnalyticsComponentBody;
