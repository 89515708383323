import React from "react";
import "./sideApp.scss";
import Header from "./Header";

function MainHeader() {
  return (
    <div className="App">
      <Header className="position-ab newSIderSTyle" />
    </div>
  );
}

export default MainHeader;
