import "./MainFooter.scss";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import LanguageTranslate from "../../../languageTest";
import { FaWheelchair } from "react-icons/fa";
import {
  getSideNavSelector,
  propertyDataSelector,
  themeSelector,
} from "../../../Recoil/themeModule";
import { getSubscribeEmailData } from "../../../DataLayer/datalayerUtilities";
import ReCAPTCHA from "react-google-recaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import comfortInnLogo from "../assets/images/comfortInnLogo.svg";
import { useMediaQuery } from "react-responsive";

const MainFooter = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 993px)",
  });
  const [sideNav, setSideNav] = useRecoilState(getSideNavSelector);
  const recaptchaRef = useRef(null);
  const formSchema = Yup.object().shape({
    subscriber_email: Yup.string()
      .required("Please Enter Your Email Address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Please Enter Valid Email Address"
      ),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
  } = useForm(formOptions);
  const propertyData = useRecoilValue(propertyDataSelector);
  const themeSelectorData = useRecoilValue(themeSelector);
  const invisibleCaptchaKey = themeSelectorData.invisiblenoCaptchaSiteKey;
  const pathname = useLocation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  const [roomNoMarketing, setRoomNoMarketing] = useState(1);
  const [childNoMarketing, setChildNoMarketing] = useState(0);
  const [adultNoMarketing, setAdultNoMarketing] = useState(
    propertyData?.base_occupancy
  );
  const waveerro = document.querySelectorAll("h2.mb-3");
  for (let i = 0; i < waveerro.length; i++) {
    waveerro[i].textContent = "Yosemeite";
    waveerro[i].classList.add("d-none");
  }

  useEffect(() => {
    if (errors.subscriber_email) {
      alert(errors.subscriber_email.message);
    }
  }, [errors]);

  // Sojern Script
  useEffect(() => {
    setTimeout(() => {
      import("../../../Sojern.js").then(() => {
        console.log("Sojern.js imported");
      });
    }, 10000);
  }, []);
  // Sojern Script

  const h1scroll = () => {
    const element = document.querySelector("h1");
    if (element) {
      if (window.screen.availWidth > 1199) {
        let info1topoffset = element.offsetTop;
        let checkAvail =
          document.querySelector("#showCAonClickBtn").offsetHeight || 115;
        window.scrollTo({
          top: info1topoffset - checkAvail + 80,
          behavior: "smooth",
        });
      } else {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (sideNav === false && pathname.pathname !== "/") {
      setTimeout(() => {
        h1scroll();
      }, 2000);
    }
  }, [pathname.pathname]);

  const onSubmit = async (data) => {
    const logo_path = "";
    const subscriber_name = "";
    var dateWhen = Date().toLocaleString();
    const recaptchaValue = await recaptchaRef.current.executeAsync();
    const { email, property_name } = propertyData;

    const finalData = {
      ...data,
      logo_path,
      subscriber_name,
      dateWhen,
      property_name,
      email,
      captcha: recaptchaValue,
      theme: themeSelectorData.theme,
    };

    const response = await getSubscribeEmailData(finalData);

    if (response.data === "success") {
      alert("Thank You! You Have Successfully Subscribed To The Mailing List.");
      document.getElementById("subscriber_email").value = "";
    } else if (response.data === "Erremail") {
      alert(
        "Sorry, Your Email Address Is Already Subscribed. Please Enter Another Email Address."
      );
    } else if (response.data === "Invalid") {
      if (document.getElementById("subscriber_email").value == "") {
        alert("Enter Your Email Address.");
      } else {
        alert("Sorry, You Have Entered An Invalid Email Address.");
      }
    } else {
      alert(
        "We Are Sorry, We Have Experienced A Technical Issue. Please Try Again After Some Time."
      );
    }

    reset({
      subscriber_email: "",
    });
    if (recaptchaRef?.current) {
      recaptchaRef?.current.reset();
    }
  };

  return (
    <div className="Footer-Style-SantaCruzComfortInn px-0 common-footer">
      {isDesktop ? (
        <div className="col-9 mx-auto">
          <section className="row first_section my-5 px-2">
            <div className="col-3 text-light d-flex flex-column justify-content-between footer_paSec">
              <Link to="/overview" title="About Us">
                About Us
              </Link>
              <Link to="/sitemap" title="Sitemap">
                Sitemap
              </Link>
              <Link to="/cookies" title="Cookie Policy">
                Cookie Policy
              </Link>
              <Link to="/privacy-policy" title="Privacy Policy">
                Privacy Policy
              </Link>
              <Link to="/terms-conditions" title="Terms & Conditions">
                Terms & Conditions
              </Link>
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-6">
                  <fieldset className="comfortInnTitle text-light border-light">
                    <legend>subscribe to our</legend>
                    <h3>newsletter</h3>
                  </fieldset>
                </div>
                <div className="d-flex col-6 justify-content-end align-items-end">
                  <div className="Accbtn mr-3">
                    {propertyData.accessibilty_status && (
                      <Link
                        to="/ada-accessibility-amenities-services-facilities"
                        className="wheelchair-m d-flex justify-content-center"
                        title="Accessibility"
                      >
                        <span className="wheel-text d-flex button-ada text-decoration-none">
                          <FaWheelchair size="20" className="on-h-Icon mr-1" />
                          <span>Accessibility</span>
                        </span>
                      </Link>
                    )}
                  </div>
                  <div className="lang-btn">
                    <LanguageTranslate />
                    <label
                      htmlFor="goog-gt-votingInputSrcLang"
                      className="d-none"
                    >
                      one
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputTrgLang"
                      className="d-none"
                    >
                      two
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputSrcText"
                      className="d-none"
                    >
                      three
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputTrgText"
                      className="d-none"
                    >
                      four
                    </label>
                    <label htmlFor="goog-gt-votingInputVote" className="d-none">
                      five
                    </label>
                  </div>
                </div>
              </div>

              <div className="subscribe_wrapper mt-5">
                <div className="text-center text-white">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="mt-3 newsletter-form"
                  >
                    <label
                      htmlFor="subscriber_email"
                      className="customWaveErrorResolve"
                    >
                      subscriber email
                    </label>

                    <input
                      id="subscriber_email"
                      placeholder="Enter Your Email Address"
                      className={`form-control form-controlled ltr-none bdr-left-rght-radius ${
                        errors.subscriber_email && "invalid"
                      }`}
                      {...register("subscriber_email", {})}
                      onKeyUp={() => {
                        trigger("subscriber_emails");
                      }}
                    />

                    <label htmlFor="g-recaptcha-response" className="d-none">
                      g-recaptcha-response
                    </label>
                    <label htmlFor="g-recaptcha-response-1" className="d-none">
                      g-recaptcha-response-1
                    </label>
                    <label htmlFor="g-recaptcha-response-2" className="d-none">
                      g-recaptcha-response-2
                    </label>

                    <ReCAPTCHA
                      ref={recaptchaRef}
                      // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      sitekey={invisibleCaptchaKey}
                      size="invisible"
                    />
                    <button
                      className="footer-conmfort-btn"
                      type="submit"
                      title="Sign up"
                    >
                      Sign up
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <section className="second_section">
            <div className="bg-light logo-section row">
              <div className="col-5 d-flex flex-column justify-content-center hotel-links pl-5">
                <p title="Hotels in Santa Cruz">
                  <Link to="/overview">Hotels in Santa Cruz</Link>
                </p>
                <p title="Santa Cruz Hotel Guest Rooms">
                  <Link to="/guestrooms">Santa Cruz Hotel Guest Rooms</Link>
                </p>
              </div>
              <div className="col-2">
                <Link to="/" className="logo">
                  <img
                    src={comfortInnLogo}
                    alt="comfortInnLogo"
                    loading="lazy"
                    width="122"
                    height="120"
                  />
                </Link>
              </div>
              <div className="col-5 text-right d-flex flex-column justify-content-center hotel-links pr-5">
                <p title="Santa Cruz Hotel Accommodations">
                  <Link
                    to="/reservations"
                    target="_blank"
                    onClick={() => {
                      localStorage.setItem(
                        "reservationUrlData",
                        JSON.stringify({
                          startDate,
                          endDate,
                          roomNoMarketing,
                          adultNoMarketing,
                          childNoMarketing,
                        })
                      );
                    }}
                  >
                    Santa Cruz Hotel Accommodations
                  </Link>
                </p>
                <p title="Attractions in Santa Cruz California">
                  <Link
                    to={`/${propertyData?.city
                      ?.split(" ")
                      .join("-")}-attractions`}
                  >
                    {" "}
                    Attractions in Santa Cruz California
                  </Link>
                </p>
              </div>
            </div>
            <div className="copyright-section">
              <div className="d-flex justify-content-between border-bottom text-light py-2">
                <p className="copy-right-line1">
                  Website Design, Development, and Digital Marketing{" "}
                  <a
                    target="_blank"
                    href="https://www.innsight.com/hospitality-website-design"
                    rel="noreferrer"
                  >
                    <u className="Innu">
                      <i>Powered by INNsight</i>
                    </u>
                  </a>
                </p>
                <p>​Copyright © 2024 INNsight.com, Inc</p>
              </div>
              <div
                className={
                  pathname.pathname === "/overview"
                    ? "text-light text-center p-2 copySec1"
                    : "text-light text-center p-2"
                }
              >
                This website is maintained by Comfort Inn Santa Cruz
                Beach/Boardwalk, a licensed user of certain proprietary
                trademarks and service marks of Choice Hotels International,
                Inc.
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="container text-center text-light">
          <Link to="/" className="logo">
            <img
              loading="lazy"
              src={comfortInnLogo}
              alt="comfortInnLogo"
              className="d-block mx-auto mt-3"
              width="144"
              height="142"
            />
          </Link>
          <h3 className="text-light my-4 subsCr">
            Subscribe To Our Newsletter
          </h3>
          <div className="subscribe_wrapper">
            <div className="text-center text-white">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-3 newsletter-form"
              >
                <label
                  htmlFor="subscriber_email"
                  className="customWaveErrorResolve"
                >
                  subscriber email
                </label>

                <input
                  id="subscriber_email"
                  placeholder="Enter Your Email Address"
                  className={`form-control form-controlled ltr-none bdr-left-rght-radius ${
                    errors.subscriber_email && "invalid"
                  }`}
                  {...register("subscriber_email", {})}
                  onKeyUp={() => {
                    trigger("subscriber_emails");
                  }}
                />

                <label htmlFor="g-recaptcha-response" className="d-none">
                  g-recaptcha-response
                </label>
                <label htmlFor="g-recaptcha-response-1" className="d-none">
                  g-recaptcha-response-1
                </label>
                <label htmlFor="g-recaptcha-response-2" className="d-none">
                  g-recaptcha-response-2
                </label>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  sitekey={invisibleCaptchaKey}
                  size="invisible"
                />
                <button
                  className="footer-conmfort-btn"
                  type="submit"
                  title="Sign up"
                >
                  Sign up
                </button>
              </form>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-12 col-md-6 text-center text-md-left">
              <p className="mb-1">
                <Link to="/overview">Hotels in Santa Cruz</Link>
              </p>
              <p>
                <Link to="/guestrooms">Santa Cruz Hotel Guest Rooms</Link>
              </p>
            </div>
            <div className="col-12 col-md-6 text-center text-md-right">
              <p className="mb-1">
                <Link
                  to="/reservations"
                  target="_blank"
                  onClick={() => {
                    localStorage.setItem(
                      "reservationUrlData",
                      JSON.stringify({
                        startDate,
                        endDate,
                        roomNoMarketing,
                        adultNoMarketing,
                        childNoMarketing,
                      })
                    );
                  }}
                >
                  Santa Cruz Hotel Accommodations
                </Link>
              </p>
              <p>
                <Link
                  to={`/${propertyData?.city
                    ?.split(" ")
                    .join("-")}-attractions`}
                >
                  Attractions in Santa Cruz California
                </Link>
              </p>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <div className="Accbtn mb-2 mb-md-0 mr-0 mr-md-4">
              {propertyData.accessibilty_status && (
                <Link
                  to="/ada-accessibility-amenities-services-facilities"
                  className="wheelchair-m d-flex justify-content-center"
                >
                  <span className="wheel-text d-flex button-ada text-decoration-none">
                    <FaWheelchair size="20" className="on-h-Icon mr-1" />
                    <span>Accessibility</span>
                  </span>
                </Link>
              )}
            </div>
            <div className="lang-btn mb-0">
              <LanguageTranslate />
              <label htmlFor="goog-gt-votingInputSrcLang" className="d-none">
                one
              </label>
              <label htmlFor="goog-gt-votingInputTrgLang" className="d-none">
                two
              </label>
              <label htmlFor="goog-gt-votingInputSrcText" className="d-none">
                three
              </label>
              <label htmlFor="goog-gt-votingInputTrgText" className="d-none">
                four
              </label>
              <label htmlFor="goog-gt-votingInputVote" className="d-none">
                five
              </label>
            </div>
          </div>
          <div className="copyright-section mt-4 mb-5">
            <div className="d-flex flex-column border-bottom text-light py-2">
              <p className="copy-right-line1">
                Website Design, Development, and Digital Marketing{" "}
                <a
                  target="_blank"
                  href="https://www.innsight.com/hospitality-website-design"
                  rel="noreferrer"
                >
                  <u className="Innu">
                    <i>Powered by INNsight</i>
                  </u>
                </a>
              </p>
              <p>​Copyright © {new Date().getFullYear()} INNsight.com, Inc</p>
            </div>
            <div
              className={
                pathname.pathname === "/overview"
                  ? "text-light text-center p-2 copySec1"
                  : "text-light text-center p-2"
              }
            >
              This website is maintained by Comfort Inn Santa Cruz
              Beach/Boardwalk, a licensed user of certain proprietary trademarks
              and service marks of Choice Hotels International, Inc.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainFooter;
