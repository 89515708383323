import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { FiPhoneCall } from "react-icons/fi";
import "bootstrap/dist/css/bootstrap.css";
import "./slidemenu.scss";
import {
  propertyDataSelector,
  getSideNavSelector,
} from "../../../Recoil/themeModule";
import LocationIcon from "../assets/Icons/locationn.svg";
import MailIcon from "../assets/Icons/mail.svg";
import { Squash as Hamburger } from "hamburger-react";
import { useRecoilValue } from "recoil";

const SlideMenuComponent = () => {
  const propertyData = useRecoilValue(propertyDataSelector);
  const sideNav = useRecoilValue(getSideNavSelector);
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const [open, setopen] = useState(false);
  const [isHumOpen, setHumOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState([]);
  console.log("propData@@@", propertyData?.is_ibe);
  let alttitleImgLoc = `${propertyData.street_address}, ${propertyData.city}, ${propertyData.state}, ${propertyData.zip_code}`;

  let dPhone = `${
    propertyData &&
    propertyData.primary_phone_no &&
    propertyData.primary_phone_no.split("/")[1]
  }`;

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "100%";
    document.getElementById("#menuStatus").style.display = "none";
    document.querySelector("#myInput").checked = false;
    document.querySelector(".open-close-icon").classList.remove("menuOpen");
    setHumOpen(false);
  };

  const controlMenu = () => {
    if (document.querySelector("body").classList.contains("menu_activated")) {
      document.querySelector("body").classList.remove("menu_activated");
    } else {
      document.querySelector("body").classList.add("menu_activated");
    }
  };

  let eventCall = "";
  const menuItem = [
    {
      name: "Home",
      active: false,
      title: "Home",
      url: "/",
      handleScroll: controlMenu,
    },
    {
      name: "About Us",
      title: "About Us",
      children: [
        {
          name: "Our Services",
          url: "/overview",
          title: "Our Services",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.overview_status,
        },

        {
          name: "Credit Card",
          url: "/credit-card",
          title: "Credit Card",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.static_page_credit_card_status,
        },

        {
          name: "Pool & Spa",
          url: "/pool",
          title: "Pool & Spa",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.static_page_pool_status,
        },

        {
          name: "Parking",
          url: "/parking-page",
          title: "Parking",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.static_page_parking_status,
        },
        {
          name: "Top Reasons To Stay",
          url: "/top-reasons-to-stay",
          title: "Top Reasons To Stay",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.topstay_page_status,
        },
        {
          name: "Fitness",
          url: "/fitness",
          title: "Fitness",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.static_page_fitnessroom_status,
        },
        {
          name: "Pets",
          url: "/pets",
          title: "Pets",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.static_page_pets_status,
        },
        {
          name: "EV Charging",
          url: "/ev-charging",
          title: "EV Charging",
          active: false,
          handleScroll: controlMenu,
          status: "1",
        },
      ],
      active: false,
    },

    {
      name: "Rooms",
      active: false,
      url: "/guestrooms",
      title: "Rooms",
      handleScroll: controlMenu,
      status: propertyData.guest_room_status,
    },
    {
      name: "Reviews",
      active: false,
      url: "/reviews",
      title: "Reviews",
      handleScroll: controlMenu,
      status: propertyData.innsight_status,
    },
    {
      name: "Gallery",
      title: "Gallery",
      children: [
        {
          name: "Pictures",
          url: "/gallery",
          active: false,
          title: "Pictures",
          handleScroll: controlMenu,
          status: propertyData.gallery_status,
        },
        {
          name: "3D-Virtual-Tour",
          url: "/3d-virtual-tours",
          title: "3D-Virtual-Tour",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.panographic_status,
        },
        {
          name: "Videos",
          url: "/videos",
          title: "Videos",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.video_status,
        },
      ],
      active: false,
    },
    {
      name: "Specials",
      title: "Specials",
      children: [
        {
          name: "Packages",
          url: "/packages",
          active: false,
          title: "Packages",
          handleScroll: controlMenu,
          status: propertyData.package_status,
        },
        {
          name: "Friends",
          url: "/friends",
          title: "Friends",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.friend_status,
        },
      ],
      active: false,
    },

    {
      name: "Destination",
      title: "Destination",
      children: [
        {
          name: "Attractions",
          url: `/${propertyData?.city?.split(" ").join("-")}-attractions`,
          title: "Attractions",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.attraction_status,
        },
        {
          name: "Things To Do",
          url: "/things-to-do",
          title: "Things To Do",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.thingstodoStatus,
        },
        {
          name: "Yosemite Shuttle",
          url: "https://yarts.com/routes/sonora-hwy-120/",
          title: "Yosemite Shuttle",
          active: false,
          handleScroll: controlMenu,
          status: "1",
        },
        {
          name: "Events",
          url: "/events",
          active: false,
          title: "Events",
          handleScroll: controlMenu,
          status: propertyData.event_status,
        },
        {
          name: "Yosemite Guided Tours",
          url: "/yosemite-guided-tours",
          title: "Yosemite Guided Tours",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.your_guide_onoff_status,
        },
        {
          name: "Weather",
          url: "/weather",
          title: "Weather",
          active: false,
          handleScroll: controlMenu,
          status: propertyData.weather_status,
        },
        {
          name: "Hiking",
          url: "/hiking",
          title: "Hiking",
          active: false,
          handleScroll: controlMenu,
          status: "1",
        },
      ],
      active: false,
    },

    {
      name: "Groups",
      active: false,
      url: "/six-plus-booking",
      title: "Groups",
      handleScroll: controlMenu,
      status: "1",
    },

    {
      name: "Accessibility",
      title: "Accessibility",
      active: false,
      url: "/ada-accessibility-amenities-services-facilities",
      handleScroll: controlMenu,
      status: propertyData.accessibilty_status,
    },
    {
      name: "Reservation",
      active: false,
      title: "Reservation",
      url: "/reservations",
      checkMenu: openNav,
      status: "1",
    },
  ];

  const handlerOnHoverItem = (list) => {
    setSelectedMenu(list);
  };

  const toggleMenu = function () {
    let toggleMenuActive = document.querySelector(".fullmenuslideDiv");
    document.querySelector(".humBurger-menu").classList.toggle("close");
    toggleMenuActive.classList.toggle("open");
    setopen(!open);
  };

  const DropDownprevent = (e) => {
    e.preventDefault();
    let ddList = e.target.offsetParent;
    if (ddList.className.includes("dropDownList")) {
      ddList.classList.toggle("open-menu");
    }
  };

  useEffect(() => {
    if (open) {
      toggleMenu();
    }
    if (document.querySelector("#myInput")) {
      document.querySelector("#myInput").checked = false;
    }
    if (document.querySelector(".open-close-icon")) {
      document.querySelector(".open-close-icon").classList.remove("menuOpen");
    }

    setHumOpen(false);
    document.querySelector(".App").classList.remove("menu_activated");
  }, [pathname]);

  useEffect(activeTab, [selectedMenu]);

  const openIcon = () => {
    document.querySelector(".open-close-icon").classList.toggle("menuOpen");
    var checkMenu = document.querySelector(".open-close-icon");
    if (document.querySelector("#menuStatus").classList.contains("menuOpen")) {
      checkMenu.title = "Close";
    } else {
      checkMenu.title = "Menu";
    }

    if (document.querySelector(".App").classList.contains("menu_activated")) {
      document.querySelector(".App").classList.remove("menu_activated");
    } else {
      document.querySelector(".App").classList.add("menu_activated");
    }
  };

  let header = document.getElementById("myDIV");
  let btns;

  const activemenumain = document.querySelectorAll(".tester.nav_links");
  const dropDownListMenu = document.querySelectorAll(".nav_link dropDownList");
  setTimeout(() => {
    for (let i = 0; i <= activemenumain.length - 1; i++) {
      activemenumain[i].addEventListener("click", function () {
        activemenumain[i].class = "active";
        dropDownListMenu[i].class = "active";
      });
    }
  }, "100");

  function activeTab() {
    if (header) {
      let btns = header.getElementsByClassName("menuActived");
      let navLinks = document.getElementsByClassName("nav_links");
      [...navLinks].forEach((nl) => {
        nl.parentElement.classList.contains("active") &&
          nl.parentElement.classList.remove("active");
        let hrefActive = nl.getAttribute("href");
        const extractedPath = `/${pathname.split("/")[1]}`;
        extractedPath === hrefActive &&
          nl.parentElement.classList.add("active");
      });
    }
  }
  activeTab();

  // Pm-edits- for each sub nav Nav underline should be there for desktop
  useEffect(() => {
    menuItem.forEach((menu) => {
      if (menu.hasOwnProperty("children")) {
        menu.children.forEach((child) => {
          if (child.url === pathname) {
            const elements = document.getElementsByClassName("menuActived");
            const elementsArray = Array.from(elements);
            elementsArray.forEach((item, index) => {
              if (item.innerText === menu.name) {
                item.classList.add("active");
              }
            });
          }
        });
      }
    });
  });
  // Pm-edits- for each sub nav Nav underline should be there for desktop

  useEffect(() => {
    const temp = document.getElementsByClassName("open-close-icon");

    if (temp[0] && temp[0].classList.contains("menuOpen")) {
      document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    }
    if (
      temp[0] &&
      !temp[0].classList.contains("menuOpen") &&
      sideNav === false
    ) {
      document.getElementsByTagName("body")[0].style.overflowY = "scroll";
    }
  });

  return (
    <div className="newmenuStyle">
      <div className="container px-0">
        <input type="checkbox" id="myInput" />
        <label
          htmlFor="myInput"
          id="menuStatus"
          onClick={openIcon}
          className="px-0 humBurger-menu1 open-close-icon"
          title="Menu"
        >
          <Hamburger
            toggled={isHumOpen}
            toggle={setHumOpen}
            className="text-white"
            onClick={openIcon}
            size="40"
          />
          <span className="d-none">menuicon</span>
        </label>
        <aside className="PR full-aside">
          <div className="wrapperMenu">
            <div className="aside-section aside-left aside-left-mob">
              {selectedMenu.length > 0 &&
              selectedMenu.filter((sub) => {
                return sub.status === "1";
              }).length > 0 ? (
                <div className="trans-center">
                  <div className="row mx-auto justify-content-center align-items-center flex-column vh-100">
                    <div className="row w-70 my-3 mx-0">
                      <ul className="sub-list" id="subDiv">
                        {selectedMenu
                          .filter((sub) => {
                            return sub.status === "1";
                          })
                          .map((menu, index) => {
                            return (
                              <li
                                key={index}
                                className="my-3 menuSubList menuActived"
                              >
                                {menu.title === "Yosemite Shuttle" ? (
                                  <a
                                    className="subNavLink nav_links"
                                    onClick={menu.handleScroll}
                                    title={menu.title}
                                    href={menu.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {menu.name}
                                  </a>
                                ) : (
                                  <Link
                                    className="subNavLink nav_links"
                                    to={menu.url}
                                    onClick={menu.handleScroll}
                                    title={menu.title}
                                  >
                                    {menu.name}
                                  </Link>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pl-5">
                  <div className="aside-content">
                    <div className="side-title">LOCATE US</div>
                  </div>
                  <div className="col-12">
                    <div className="mt-0 mb-3 ">
                      <div className="d-flex">
                        <img
                          src={LocationIcon}
                          className="icons-size"
                          alt="Address"
                          title="Address"
                        />
                        <div className="p-title">
                          Address:
                          <span
                            className="p-content-menu"
                            title="7633 State Highway 120,Groveland, California,USA"
                          >
                            <br />
                            <a
                              href="https://goo.gl/maps/yv65gcGQKpkWBdEg9"
                              target="blank"
                              className="contactNo"
                            >
                              {" "}
                              <span
                                className="loc-subTitle"
                                title={alttitleImgLoc}
                              >
                                {propertyData?.street_address},&nbsp;
                                {propertyData?.city}
                                ,&nbsp;
                                <br />
                                {propertyData?.state},&nbsp;
                                {propertyData?.zip_code},&nbsp;
                                {propertyData?.country}
                              </span>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="my-4">
                      <div className="d-flex">
                        <FiPhoneCall
                          size="40px"
                          className="phoneIcon"
                          alt="two"
                          title="Phone"
                        />
                        <div className="p-title p-titleSec">
                          Phone:
                          <span className="loc-subTitle">
                            <a
                              className="text-decoration-none"
                              title={dPhone}
                              href={`tel:${
                                propertyData &&
                                propertyData.primary_phone_no &&
                                propertyData.primary_phone_no.split("/")[1]
                              }`}
                            >
                              <p className="p-content-menu pl-0">
                                {" "}
                                {propertyData &&
                                  propertyData.primary_phone_no &&
                                  propertyData.primary_phone_no.split("/")[1]}
                              </p>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <img
                        src={MailIcon}
                        className="icons-size"
                        alt="Email"
                        title="Email"
                      />
                      <div className="p-title">
                        Email:{" "}
                        <span className="p-content-menu">
                          <br />
                          <a
                            className="text-decoration-none p-content-menu pl-0"
                            title={`${propertyData.email}`}
                            href={`mailto:${propertyData.email}`}
                          >
                            <p className="p-content-menu pl-0">
                              {propertyData.email}
                            </p>
                          </a>
                        </span>
                      </div>
                    </div>
                    {propertyData.direction_status === "1" && (
                      <div className=" bg-image-menu">
                        <div className="mt-0">
                          <Link to="/directions">
                            <button
                              type="button"
                              className=" mt-3 get-btn text-uppercase"
                              title="Get Directions"
                            >
                              Get Directions
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="aside-section aside-right pl-4 pr-1">
              <div className="new code menuControl">
                <div className="aside-list text-capitalise aside-anchor ">
                  <ul className="left-list" id="myDIV">
                    {menuItem.length &&
                      menuItem.map((item, index) => {
                        let firstEleAct;
                        eventCall =
                          item.name === "Reservation"
                            ? item.checkMenu
                            : item.handleScroll;
                        {
                          index == 0
                            ? (firstEleAct = "menuActived active")
                            : (firstEleAct = "menuActived");
                        }

                        if (item.name === "Home") {
                          return (
                            <li
                              className={firstEleAct}
                              key={index}
                              onMouseOver={() =>
                                handlerOnHoverItem(item?.children || [])
                              }
                            >
                              <Link
                                className="tester nav_links"
                                to={item.url}
                                title={item.title}
                              >
                                {item.name}
                              </Link>
                            </li>
                          );
                        } else {
                          if ("children" in item) {
                            if (
                              item.children.filter(
                                (each) => each.status === "1"
                              ).length > 0
                            ) {
                              return (
                                <li
                                  className={firstEleAct}
                                  key={index}
                                  onMouseOver={() =>
                                    handlerOnHoverItem(item?.children || [])
                                  }
                                >
                                  <Link
                                    className="tester nav_links"
                                    to={item.url}
                                    title={item.title}
                                  >
                                    {item.name}
                                  </Link>
                                </li>
                              );
                            }
                          } else {
                            if (item.status === "1") {
                              return (
                                <li
                                  className={firstEleAct}
                                  key={index}
                                  onMouseOver={() =>
                                    handlerOnHoverItem(item?.children || [])
                                  }
                                >
                                  <Link
                                    className="tester nav_links"
                                    to={item.url}
                                    target={
                                      item.name === "Reservation"
                                        ? "_blank"
                                        : "_self"
                                    }
                                    title={item.title}
                                  >
                                    {item.name}
                                  </Link>
                                </li>
                              );
                            }
                          }
                        }
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* mobile view  */}
          <div className="aside-section aside-right d-md-block d-block d-lg-none d-xl-none mobile-view">
            <div className="new code">
              <div className="aside-list text-capitalise aside-anchor">
                <div className="nav_container mx-0 container px-0">
                  <ul className="menuMainLink mob-links">
                    <li
                      className={[
                        splitLocation[1] === "" ? "active" : "",
                        "nav_link",
                      ].join(" ")}
                    >
                      <Link to="/" title="Home" className="tester">
                        Home
                      </Link>
                    </li>
                    {/* About Us  */}
                    {menuItem.filter((item) => item.name === "About Us") &&
                      menuItem
                        .filter((item) => item.name === "About Us")[0]
                        .children?.filter((each) => each.status === "1")
                        .length > 0 && (
                        <li
                          className={
                            menuItem
                              .filter((menu) => menu.name === "About Us")[0]
                              .children.filter(
                                (item) => item.url === "/" + splitLocation[1]
                              ).length !== 0
                              ? "nav_link dropDownList active"
                              : "nav_link dropDownList"
                          }
                        >
                          <Link
                            title="About Us"
                            to=""
                            onClick={(e) => {
                              DropDownprevent(e);
                            }}
                            className="tester"
                          >
                            About Us
                          </Link>
                          <ul className="dropdown-menus-overveiw">
                            {propertyData.overview_status === "1" && (
                              <li
                                className={
                                  splitLocation[1] === "overview"
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  to="/overview"
                                  title="Our Services"
                                  className="tester"
                                >
                                  Our Services
                                </Link>
                              </li>
                            )}

                            {propertyData.static_page_credit_card_status ===
                              "1" && (
                              <li
                                className={
                                  splitLocation[1] === "credit-card"
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  to="/credit-card"
                                  title="Credit Card"
                                  className="tester"
                                >
                                  Credit Card
                                </Link>
                              </li>
                            )}

                            {propertyData.static_page_parking_status ===
                              "1" && (
                              <li
                                className={
                                  splitLocation[1] === "parking-page"
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  to="/parking-page"
                                  title="Parking"
                                  className="tester"
                                >
                                  Parking
                                </Link>
                              </li>
                            )}

                            {propertyData.static_page_pool_status === "1" && (
                              <li
                                className={
                                  splitLocation[1] === "pool" ? "active" : ""
                                }
                              >
                                <Link
                                  to="/pool"
                                  title="Pool & Spa"
                                  className="tester"
                                >
                                  Pool & Spa
                                </Link>
                              </li>
                            )}

                            {propertyData.topstay_page_status === "1" && (
                              <li
                                className={
                                  splitLocation[1] === "top-reasons-to-stay"
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  to="/top-reasons-to-stay"
                                  title="Top Reason To Stay"
                                  className="tester"
                                >
                                  Top Reason To Stay
                                </Link>
                              </li>
                            )}

                            {propertyData.static_page_fitnessroom_status ===
                              "1" && (
                              <li
                                className={
                                  splitLocation[1] === "fitness" ? "active" : ""
                                }
                              >
                                <Link
                                  to="/fitness"
                                  title="Fitness"
                                  className="tester"
                                >
                                  Fitness
                                </Link>
                              </li>
                            )}

                            {propertyData.static_page_pets_status === "1" && (
                              <li
                                className={
                                  splitLocation[1] === "pets" ? "active" : ""
                                }
                              >
                                <Link
                                  to="/pets"
                                  title="Pets"
                                  className="tester"
                                >
                                  Pets
                                </Link>
                              </li>
                            )}
                            <li
                              className={
                                splitLocation[1] === "ev-charging"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                to="/ev-charging"
                                title="Ev Charging"
                                className="tester"
                              >
                                Ev Charging
                              </Link>
                            </li>
                          </ul>
                        </li>
                      )}
                    {/* Rooms  */}
                    {menuItem.filter((item) => item.name === "Rooms")[0]
                      .status === "1" && (
                      <li
                        className={
                          splitLocation[1] === "guestrooms"
                            ? "nav_link active"
                            : "nav_link"
                        }
                      >
                        <Link title="Rooms" to="/guestrooms" className="tester">
                          Rooms
                        </Link>
                      </li>
                    )}

                    {/* Reviews  */}
                    {menuItem.filter((item) => item.name === "Reviews")[0]
                      .status === "1" && (
                      <li
                        className={
                          splitLocation[1] === "reviews"
                            ? "nav_link active"
                            : "nav_link"
                        }
                      >
                        <Link title="Reviews" to="/reviews" className="tester">
                          Reviews
                        </Link>
                      </li>
                    )}

                    {/* Gallery  */}
                    {menuItem
                      .filter((item) => item.name === "Gallery")[0]
                      .children.filter((each) => each.status === "1").length >
                      0 && (
                      <li
                        className={
                          menuItem
                            .filter((menu) => menu.name === "Gallery")[0]
                            .children.filter(
                              (item) => item.url === "/" + splitLocation[1]
                            ).length !== 0
                            ? "nav_link dropDownList active"
                            : "nav_link dropDownList"
                        }
                      >
                        <Link
                          title="Gallery"
                          to=""
                          onClick={(e) => {
                            DropDownprevent(e);
                          }}
                          className="tester"
                        >
                          Gallery
                        </Link>
                        <ul className="dropdown-menus-overveiw">
                          {propertyData.gallery_status === "1" && (
                            <li
                              className={
                                splitLocation[1] === "gallery" ? "active" : ""
                              }
                            >
                              <Link
                                to="/gallery"
                                title="Pictures"
                                className="tester"
                              >
                                Pictures
                              </Link>
                            </li>
                          )}

                          {propertyData.video_status === "1" && (
                            <li
                              className={
                                splitLocation[1] === "videos" ? "active" : ""
                              }
                            >
                              <Link
                                to="/videos"
                                title="Videos"
                                className="tester"
                              >
                                Videos
                              </Link>
                            </li>
                          )}

                          {propertyData.panographic_status === "1" && (
                            <li
                              className={
                                splitLocation[1] === "3d-virtual-tours"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                to="/3d-virtual-tours"
                                title="3D-Virtual-Tour"
                                className="tester"
                              >
                                3D-Virtual-Tour
                              </Link>
                            </li>
                          )}
                        </ul>
                      </li>
                    )}
                    {/* specials  */}
                    {menuItem
                      .filter((item) => item.name === "Specials")[0]
                      .children.filter((each) => each.status === "1").length >
                      0 && (
                      <li
                        className={
                          menuItem
                            .filter((menu) => menu.name === "Specials")[0]
                            .children.filter(
                              (item) => item.url === "/" + splitLocation[1]
                            ).length !== 0
                            ? "nav_link dropDownList active"
                            : "nav_link dropDownList"
                        }
                      >
                        <Link
                          title="Specials"
                          to=""
                          onClick={(e) => {
                            DropDownprevent(e);
                          }}
                          className="tester"
                        >
                          Specials
                        </Link>
                        <ul className="dropdown-menus-overveiw">
                          {propertyData.package_status === "1" && (
                            <li
                              className={
                                splitLocation[1] === "Specials" ? "active" : ""
                              }
                            >
                              <Link
                                to="/packages"
                                title="Packages"
                                className="tester"
                              >
                                Packages
                              </Link>
                            </li>
                          )}

                          {propertyData.friend_status === "1" && (
                            <li
                              className={
                                splitLocation[1] === "friends" ? "active" : ""
                              }
                            >
                              <Link
                                to="/friends"
                                title="Friends"
                                className="tester"
                              >
                                Friends
                              </Link>
                            </li>
                          )}
                        </ul>
                      </li>
                    )}

                    {/* Destination --> Hiking is static, as there is no option to on/of from admin */}

                    <li
                      className={
                        menuItem
                          .filter((menu) => menu.name === "Destination")[0]
                          .children.filter(
                            (item) => item.url === "/" + splitLocation[1]
                          ).length !== 0
                          ? "nav_link dropDownList active"
                          : "nav_link dropDownList"
                      }
                    >
                      <Link
                        to=""
                        title="Destination"
                        onClick={(e) => {
                          DropDownprevent(e);
                        }}
                        className="tester"
                      >
                        Destination
                      </Link>

                      <ul className="dropdown-menus destionation-dropdown ">
                        {propertyData.attraction_status === "1" && (
                          <li
                            className={
                              splitLocation[1] ===
                              `${propertyData?.city
                                ?.split(" ")
                                .join("-")}-attractions`
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              to={`/${propertyData?.city
                                ?.split(" ")
                                .join("-")}-attractions`}
                              title="Attractions"
                              className="tester"
                            >
                              Attractions
                            </Link>
                          </li>
                        )}
                        {propertyData.thingstodoStatus === "1" && (
                          <li
                            className={
                              splitLocation[1] === "things-to-do"
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              to="/things-to-do"
                              title="Things To Do"
                              className="tester"
                            >
                              Things To Do
                            </Link>
                          </li>
                        )}
                        <li
                          className={
                            splitLocation[1] === "yosemite-shuttle"
                              ? "active"
                              : ""
                          }
                        >
                          <a
                            title="Yosemite Shuttle"
                            className="tester"
                            href="https://yarts.com/routes/sonora-hwy-120/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Yosemite Shuttle
                          </a>
                        </li>
                        {propertyData.your_guide_onoff_status === "1" && (
                          <li
                            className={
                              splitLocation[1] === "yosemite-guided-tours"
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              to="/yosemite-guided-tours"
                              title="Yosemite Guided Tours"
                              className="tester"
                            >
                              Yosemite Guided Tours
                            </Link>
                          </li>
                        )}
                        {propertyData.weather_status === "1" && (
                          <li
                            className={
                              splitLocation[1] === "weather" ? "active" : ""
                            }
                          >
                            <Link
                              to="/weather"
                              title="Weather"
                              className="tester"
                            >
                              Weather
                            </Link>
                          </li>
                        )}
                        {propertyData.event_status === "1" && (
                          <li
                            className={
                              splitLocation[1] === "events" ? "active" : ""
                            }
                          >
                            <Link
                              to="/events"
                              title="Events"
                              className="tester"
                            >
                              Events
                            </Link>
                          </li>
                        )}
                        {/* hiking  */}
                        <li
                          className={
                            splitLocation[1] === "hiking" ? "active" : "g"
                          }
                        >
                          <Link to="/hiking" title="Hiking" className="tester">
                            Hiking
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li
                      className={
                        splitLocation[1] === "six-plus-booking" ? "active" : ""
                      }
                    >
                      <Link
                        to="/six-plus-booking"
                        title="Groups"
                        className="tester"
                      >
                        Groups
                      </Link>
                    </li>

                    {propertyData.contact_us_status === "1" && (
                      <li
                        className={[
                          splitLocation[1] === "contact-us" ? "active" : "",
                          "nav_link",
                        ].join(" ")}
                      >
                        <Link
                          title="Contact Us"
                          to="/contact-us"
                          className="tester"
                        >
                          Contact Us
                        </Link>
                      </li>
                    )}

                    {/* Accessibility  */}
                    {propertyData.accessibilty_status === "1" && (
                      <li
                        className={[
                          splitLocation[1] ===
                          "ada-accessibility-amenities-services-facilities"
                            ? "active"
                            : "",
                          "nav_link ",
                        ].join(" ")}
                      >
                        <Link
                          title="Accessibility"
                          to="/ada-accessibility-amenities-services-facilities"
                          className="tester"
                        >
                          Accessibility
                        </Link>
                      </li>
                    )}

                    <li
                      className={[
                        splitLocation[1] === "reservations" ? "active" : "",
                        "nav_link",
                      ].join(" ")}
                    >
                      <Link
                        title="Reservation"
                        to="/reservations"
                        target={propertyData?.is_ibe == "1" ? "_blank" : "_self"}
                        className="tester"
                      >
                        Reservation
                      </Link>
                    </li>
                    {/* )} */}

                    {/* Direction  */}
                    {propertyData.direction_status === "1" && (
                      <li
                        className={[
                          splitLocation[1] === "directions" ? "active" : "",
                          "nav_link",
                        ].join(" ")}
                      >
                        <Link
                          title="Directions"
                          to="/directions"
                          className="tester"
                        >
                          Directions
                        </Link>
                      </li>
                    )}

                    {/* sitemap  */}
                    <li
                      className={[
                        splitLocation[1] === "sitemap" ? "active" : "",
                        "nav_link",
                      ].join(" ")}
                    >
                      <Link title="Sitemap" to="/sitemap" className="tester">
                        Sitemap
                      </Link>
                    </li>

                    {/* Faq  */}
                    {propertyData.faq_status === "1" && (
                      <li
                        className={[
                          splitLocation[1] === "faq" ? "active" : "",
                          "nav_link ",
                        ].join(" ")}
                      >
                        <Link title="Faqs" className="tester" to="/faq">
                          FAQs
                        </Link>
                      </li>
                    )}

                    {/* News  */}
                    {propertyData.news_status === "1" && (
                      <li
                        className={[
                          splitLocation[1] === "news" ? "active" : "",
                          "nav_link ",
                        ].join(" ")}
                      >
                        <Link title="News" className="tester" to="/news">
                          News
                        </Link>
                      </li>
                    )}

                    {/* Lost and Found  */}
                    {propertyData.lost_and_found_status === "1" && (
                      <li
                        className={[
                          splitLocation[1] === "lost-found" ? "active" : "",
                          "nav_link ",
                        ].join(" ")}
                      >
                        <Link
                          title="Lost & Found"
                          className="tester"
                          to="/lost-found"
                        >
                          Lost & Found
                        </Link>
                      </li>
                    )}

                    {/* Jobs  */}
                    {propertyData.job_status === "1" && (
                      <li
                        className={[
                          splitLocation[1] === "jobs" ||
                          splitLocation[1] === "Jobs-Details"
                            ? "active"
                            : "",
                          "nav_link ",
                        ].join(" ")}
                      >
                        <Link title="Jobs" to="/jobs" className="tester">
                          Jobs
                        </Link>
                      </li>
                    )}

                    {/* Blog  */}
                    {propertyData.blogStatus === "1" && (
                      <li
                        className={[
                          splitLocation[1] === "blog" ? "active" : "",
                          "nav_link ",
                        ].join(" ")}
                      >
                        <Link title="Blog" to="/blog" className="tester">
                          Blog
                        </Link>
                      </li>
                    )}

                    {/* cookie  */}
                    {propertyData.is_cookies_policy === "1" && (
                      <li
                        className={[
                          splitLocation[1] === "cookies" ? "active" : "",
                          "nav_link ",
                        ].join(" ")}
                      >
                        <Link
                          title="Cookie Policy"
                          to="/cookies"
                          className="tester"
                        >
                          Cookie Policy
                        </Link>
                      </li>
                    )}

                    {/* privacy policy  */}
                    {propertyData.is_privacy_policy === "1" && (
                      <li
                        className={[
                          splitLocation[1] === "privacy-policy" ? "active" : "",
                          "nav_link ",
                        ].join(" ")}
                      >
                        <Link
                          title="Privacy Policy"
                          to="/privacy-policy"
                          className="tester"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                    )}

                    {/* termconditiosns */}
                    {propertyData.is_terms_cond === "1" && (
                      <li
                        className={[
                          splitLocation[1] === "terms-conditions"
                            ? "active"
                            : "",
                          "nav_link ",
                        ].join(" ")}
                      >
                        <Link
                          title="Terms & Conditions"
                          to="/terms-conditions"
                          className="tester"
                        >
                          Terms & Conditions
                        </Link>
                      </li>
                    )}

                    {/* personal data request  */}
                    {propertyData.is_personal_info === "1" && (
                      <li
                        className={[
                          splitLocation[1] === "personal-info-request-form"
                            ? "active"
                            : "",
                          "nav_link ",
                        ].join(" ")}
                      >
                        <Link
                          title="Personal Data Request"
                          to="/personal-info-request-form"
                          className="tester"
                        >
                          Personal Data Request
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default SlideMenuComponent;
