import React, { lazy, Suspense } from "react";
import PageNotFound from "./pageNotFound";
import { Route, Switch } from "react-router-dom";
import { propertyDataSelector } from "./Recoil/themeModule";
import { useRecoilValue } from "recoil";

// Home pages
const Homepage = lazy(() => import("./E/Yosemitewestgate/Home/Homepage"));

const InnAtSaratogaHomepage = lazy(() =>
  import("./E/InnAtSaratoga/Home/Homepage")
);

const HotellelaHomepage = lazy(() => import("./E/Hotellela/Home/Homepage"));

const ComfortInnSC = lazy(() =>
  import("./E/SantaCruzComfortInn/Home/Homepage")
);

const HotelCurrentHomepage = lazy(() =>
  import("./E/HotelCurrent/Home/Homepage")
);

const HotelCurentEvent = lazy(() =>
  import("./E/HotelCurrent/NewPage/HotelCurrent/EventSpace/EventSpace.js")
);

const RoomComponent = lazy(() => import("./E/InnerPages/Rooms"));
const GalleryComponent = lazy(() =>
  import("./E/InnerPages/Gallery/PicturesPage/index")
);

const SupportYNP = lazy(() =>
  import("./E/InnerPages/CustomPmsPage/supportYosemiteNationalPark")
);
const EventSpace = lazy(() =>
  import("./E/InnAtSaratoga/NewPage/InnAtSaratoga/EventSpace/EventSpace")
);

const SaratogaWeddingPage = lazy(() =>
  import("./E/InnAtSaratoga/NewPage/InnAtSaratoga/WeddingPage/saratoga-wedding")
);
const HotelLelaAmenities = lazy(() =>
  import("./E/Hotellela/NewPage/HotelLela/Amenities")
);
const SaratogaRfpPage = lazy(() =>
  import("./E/InnAtSaratoga/NewPage/InnAtSaratoga/RFP")
);

const SaratogaMtWineryConcertTransport = lazy(() =>
  import("./E/InnAtSaratoga/NewPage/InnAtSaratoga/Mt-winery-concert-transport")
);
const SaratogaMountainWinery = lazy(() =>
  import("./E/InnAtSaratoga/NewPage/InnAtSaratoga/The-Mountain-Winery")
);

const HotelCurrentRfpPage = lazy(() =>
  import("./E/HotelCurrent/NewPage/HotelCurrent/RFP")
);

const HotelLelaRfpPage = lazy(() =>
  import("./E/Hotellela/NewPage/HotelLela/RFP")
);

const SaratogaMap = lazy(() =>
  import("./E/InnAtSaratoga/NewPage/InnAtSaratoga/Saratoga-Map/SaratogaMap")
);
const ForgotSomethingComponent = lazy(() =>
  import(
    "./E/InnAtSaratoga/NewPage/InnAtSaratoga/Forgot-Something/ForgotSomthing"
  )
);
const SaratogaMeetingPage = lazy(() =>
  import("./E/InnAtSaratoga/NewPage/InnAtSaratoga/Meetings/Meetings")
);
const Dining = lazy(() =>
  import("./E/InnAtSaratoga/NewPage/InnAtSaratoga/Dining/Dining")
);
const YosemiteFamilyAdventure = lazy(() =>
  import(
    "./E/InnerPages/StoredComponent/YosemiteFamilyAdventure/YosemiteFamilyAdventure"
  )
);
const AboutComponent = lazy(() => import("./E/InnerPages/About/index"));
const WrapperFormComponent = lazy(() =>
  import("./E/InnerPages/ContactUs/WrapperForm.js")
);
const FriendsComponent = lazy(() =>
  import("./E/InnerPages/PackageComponent/FriendsComponent/friendDetailsPage")
);
const FriendDetailsPage = lazy(() =>
  import("./E/InnerPages/PackageComponent/FriendsComponent/index")
);
const NewsComponent = lazy(() => import("./E/InnerPages/News/index"));
const NewsInnerPage = lazy(() => import("./E/InnerPages/News/newsInnerPage"));
const CleaningProtocolComponent = lazy(() =>
  import("./E/InnerPages/cleaningProtocal/index")
);
const JobsComponent = lazy(() => import("./E/InnerPages/Jobs/index"));
const JobsDetailsComponent = lazy(() =>
  import("./E/InnerPages/Jobs/jobsDetails")
);
const PetsComponent = lazy(() => import("./E/InnerPages/PetsComponent/index"));
const PersonalDataRequestComponent = lazy(() =>
  import("./E/InnerPages/personalDataRequest/index")
);
const PrivacyPolicyComponent = lazy(() =>
  import("./E/InnerPages/PrivacyPolicy/index")
);
const TermsAndConditionComponent = lazy(() =>
  import("./E/InnerPages/TermsAndCondition/index")
);
const SiteMapComponent = lazy(() => import("./E/InnerPages/SiteMap/index"));
const AccessibilityComponent = lazy(() =>
  import("./E/InnerPages/Accessibility/index")
);
const VideoComponent = lazy(() => import("./E/InnerPages/Gallery/video/index"));
const FaqComponent = lazy(() => import("./E/InnerPages/FAQ/index"));
const UnsubscribeMail = lazy(() =>
  import("./E/InnerPages/UnsubscribeMail/index")
);
const WeatherComponent = lazy(() =>
  import("./E/InnerPages/DestinationPage/weather/index")
);
const SpecialComponent = lazy(() =>
  import("./E/InnerPages/PackageComponent/SpecialComponent/index")
);
const SpecialDetailsComponent = lazy(() =>
  import("./E/InnerPages/PackageComponent/SpecialComponent/specialDetails")
);
const CookiePolicyComponent = lazy(() =>
  import("./E/InnerPages/CookiePolicy/index")
);
const LostAndFoundComponent = lazy(() =>
  import("./E/InnerPages/LostAndFound/index")
);

const BlogTagsPage = lazy(() =>
  import("./E/InnerPages/BlogComponent/blogTagsPage.js")
);

const GroupTravelComponent = lazy(() =>
  import("./E/InnerPages/GroupTravel/index")
);
const DirectionComponent = lazy(() => import("./E/InnerPages/Direction/index"));

const YosemiteAdventure = lazy(() =>
  import("./E/InnerPages/DestinationPage/Guided-Tours/yosemiteAdventure")
);
const YosemiteGuide = lazy(() =>
  import("./E/InnerPages/DestinationPage/Guided-Tours/yosemiteGuide")
);

const AttractionComponent = lazy(() =>
  import("./E/InnerPages/DestinationPage/AttractionPage/index")
);
const CreditCardpage = lazy(() =>
  import("./E/InnerPages/StoredComponent/CreditCard/index")
);
const GuidedToursPage = lazy(() =>
  import("./E/InnerPages/DestinationPage/Guided-Tours/index")
);
const VirtualToursPage = lazy(() =>
  import("./E/InnerPages/Gallery/virtualTours/index")
);
const ThingsToDoPage = lazy(() =>
  import("./E/InnerPages/DestinationPage/Things-To-Do/index")
);
const Hiking = lazy(() =>
  import("./E/InnerPages/DestinationPage/Hiking/index")
);
const Archivelist = lazy(() =>
  import("./E/InnerPages/BlogComponent/Archivelist")
);
const Categorylist = lazy(() =>
  import("./E/InnerPages/BlogComponent/categorylist")
);
const BlogComponent = lazy(() => import("./E/InnerPages/BlogComponent/index"));
const BlogSearchPage = lazy(() =>
  import("./E/InnerPages/BlogComponent/BlogSearchPage.jsx")
);
const BlogDetailsPage = lazy(() =>
  import("./E/InnerPages/BlogComponent/blogDetailsPage")
);
const EventsPage = lazy(() =>
  import("./E/InnerPages/DestinationPage/Eventspage/index")
);
const EventsDetailsPage = lazy(() =>
  import("./E/InnerPages/DestinationPage/Eventspage/eventsDetailsPage")
);
const FitnessRoom = lazy(() => import("./E/InnerPages/FitnessRoom/index"));
const EvChargingComponent = lazy(() =>
  import("./E/InnerPages/EvCharging/index")
);
const GuestRoomDetailsPage = lazy(() =>
  import("./E/InnerPages/Rooms/roomDetails/roomDetailsPage")
);
const ReservationComponent = lazy(() =>
  import("./E/InnerPages/Reservation/Reservation.jsx")
);
const ReviewComponent = lazy(() => import("./E/InnerPages/Review/index"));
const ReviewDetailsPage = lazy(() =>
  import("./E/InnerPages/Review/review-details-page")
);
const AddReviewsPage = lazy(() => import("./E/InnerPages/Review/addreviews"));
const ParkingPage = lazy(() =>
  import("./E/InnerPages/StoredComponent/Parking/ParkingPage")
);
const AttractionDetailsPage = lazy(() =>
  import("./E/InnerPages/DestinationPage/AttractionPage/attractionDetailsPage")
);
const PoolPage = lazy(() =>
  import("./E/InnerPages/StoredComponent/PoolAndSpa/index")
);
const EntryReservationComponent = lazy(() =>
  import("./E/InnerPages/EntryReservationPage/EntryReservation")
);
const TransportCommunicatePage = lazy(() =>
  import("./E/InnerPages/StoredComponent/TransportCommunicate/index")
);
const CollegeAndUniversiy = lazy(() =>
  import("./E/InnerPages/StoredComponent/CollegeAndUniversityPage/index")
);
const LocalServicePage = lazy(() =>
  import("./E/InnerPages/StoredComponent/localServicePage/index")
);
const ArtCulturePage = lazy(() =>
  import("./E/InnerPages/StoredComponent/ArtandCulture/index")
);
const TopReasonToStayPage = lazy(() =>
  import("./E/InnerPages/TopReasonToStay/index")
);

const EntertainmentPage = lazy(() =>
  import("./E/InnerPages/StoredComponent/Entertainment/index")
);

// Hotel lela static pages
const HotelLelaDining = lazy(() =>
  import("./E/Hotellela/NewPage/HotelLela/Dining/Dining.jsx")
);
const HotelLelaAttraction = lazy(() =>
  import("./E/Hotellela/NewPage/HotelLela/Attraction/Attraction.jsx")
);
const HotelLelaEvents = lazy(() =>
  import("./E/Hotellela/NewPage/HotelLela/Events/index.jsx")
);
// Hotel lela static pages

const LongBeachCruiseTerminal = lazy(() =>
  import(
    "./E/HotelCurrent/NewPage/HotelCurrent/LongBeachCruiseTerminal/LongBeachCruise.jsx"
  )
);

const CalStateUniversityLongBeach = lazy(() =>
  import(
    "./E/HotelCurrent/NewPage/HotelCurrent/CalStateUniversity/CalStateUniversity.jsx"
  )
);

const AppRouting = (props) => {
  const propertyData = useRecoilValue(propertyDataSelector);

  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <div className="loader">Loading</div>
          </div>
        }
      >
        <Switch className={`switch-wrapper ${propertyData?.propertySubdomain}`}>
          {(() => {
            switch (propertyData?.propertySubdomain) {
              case "yosemitewestgate":
                return <Route exact path={"/"} component={Homepage} />;
              case "innatsaratoga":
                return (
                  <Route exact path={"/"} component={InnAtSaratogaHomepage} />
                );
              case "hotellela":
                return <Route exact path={"/"} component={HotellelaHomepage} />;
              case "hotelcurrent":
                return (
                  <Route exact path={"/"} component={HotelCurrentHomepage} />
                );
              case "santacruzcomfortinn":
                return <Route exact path={"/"} component={ComfortInnSC} />;

              default:
                return (
                  <div
                    style={{
                      height: "100vh",
                      width: "100vw",
                      display: "grid",
                      placeItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <div className="loader">Loading</div>
                  </div>
                );
            }
          })()}

          {propertyData.property_id === "761729" && (
            <Route exact path={`/dining`} component={HotelLelaDining} />
          )}
          {propertyData.property_id === "761729" && (
            <Route exact path={`/amenities`} component={HotelLelaAmenities} />
          )}

          <Route exact path={"/overview"} component={AboutComponent} />
          <Route
            exact
            path={"/yosemite-adventure-guide"}
            component={YosemiteAdventure}
          />
          <Route
            exact
            path={"/yosemite-fly-fishing-guides"}
            component={YosemiteGuide}
          />
          {(propertyData.property_id === "761723" ||
            propertyData.property_id === "275") && (
            <Route
              exact
              path={`/${propertyData?.city}-wedding`}
              component={SaratogaWeddingPage}
            />
          )}
          {propertyData.property_id === "761723" && (
            <Route exact path={`/rfp`} component={SaratogaRfpPage} />
          )}
          {propertyData.property_id === "761723" && (
            <Route
              exact
              path={`/the-mountain-winery`}
              component={SaratogaMountainWinery}
            />
          )}
          {propertyData.property_id === "761723" && (
            <Route
              exact
              path={`/mt-Winery-concert-transport`}
              component={SaratogaMtWineryConcertTransport}
            />
          )}

          {propertyData.property_id === "726622" && (
            <Route exact path={`/rfp-form`} component={HotelCurrentRfpPage} />
          )}

          {/* {propertyData.property_id === "761729" && (
            <Route exact path={`/rfp-form`} component={HotelLelaRfpPage} />
          )} */}

          {propertyData.property_id === "761723" && (
            <Route exact path={"/SaratogaMap"} component={SaratogaMap} />
          )}
          {propertyData.property_id === "761723" && (
            <Route
              exact
              path={"/forgot-something"}
              component={ForgotSomethingComponent}
            />
          )}

          {propertyData.property_id === "761723" && (
            <Route
              exact
              path={"/groups-and-meetings"}
              component={SaratogaMeetingPage}
            />
          )}

          {propertyData.property_id === "726622" && (
            <Route
              exact
              path={"/meetings-events"}
              component={HotelCurentEvent}
            />
          )}

          {propertyData.property_id === "761723" && (
            <Route exact path={"/theheid"} component={Dining} />
          )}
          <Route exact path={"/page/:page_url"} component={SupportYNP} />

          {/* blog  */}
          {/* Note: Don't change the order of blog routes otherwise blog page won't work properly -- @Priyam  */}
          <Route exact path={"/blog"} component={BlogComponent} />
          <Route exact path={"/blog/search"} component={BlogSearchPage} />
          <Route exact path={"/blog/archive_list"} component={Archivelist} />
          <Route exact path={"/blog/category_list"} component={Categorylist} />
          <Route exact path={`/blog/tags/:page_url`} component={BlogTagsPage} />
          <Route exact path={"/blog/:page_url"} component={BlogDetailsPage} />
          <Route
            exact
            path={"/blog/category/:category"}
            component={BlogComponent}
          />
          <Route
            exact
            path={"/blog/archieve/:item"}
            component={BlogComponent}
          />
          <Route
            exact
            path={"/blog/archieve/:monthItem-:yearadded"}
            component={BlogComponent}
          />
          {/* blog  */}

          {propertyData.property_id !== "761723" && (
            <Route
              exact
              path={"/ev-charging"}
              component={EvChargingComponent}
            />
          )}

          {propertyData.property_id === "265" && (
            <Route
              exact
              path={"/yosemite-family-adventures"}
              component={YosemiteFamilyAdventure}
            />
          )}

          <Route
            exact
            path={"/top-reasons-to-stay"}
            render={() => <TopReasonToStayPage />}
          />
          {/* Events  */}

          {propertyData.property_id === "761723" && (
            <Route exact path={"/events"} component={EventSpace} />
          )}

          {propertyData.property_id === "761729" && (
            <Route exact path={"/events"} component={HotelLelaEvents} />
          )}

          <Route exact path={"/events"} component={EventsPage}>
            <Route exact path={"/events/:id?"} component={EventsPage} />
          </Route>
          <Route
            exact
            path={"/events/:event_name"}
            render={(props) => <EventsDetailsPage {...props} />}
          />

          {/* Events  */}

          {propertyData?.property_id === "265" ? (
            <Route
              exact
              path={"/yosemite-guided-tours"}
              component={GuidedToursPage}
            />
          ) : (
            <Route exact path={"/guided-tours"} component={GuidedToursPage} />
          )}

          <Route
            exact
            path={"/contact-us"}
            render={() => (
              <WrapperFormComponent propertyData={props.propertyData} />
            )}
          />

          <Route exact path={`/guestrooms`} component={RoomComponent} />
          <Route
            exact
            path={`/guestrooms/:subdomain`}
            render={(props) => <GuestRoomDetailsPage {...props} />}
          />

          <Route exact path={`/fitness`} component={FitnessRoom} />
          <Route
            exact
            path={`/reservations`}
            component={ReservationComponent}
          />
          <Route
            exact
            path={`/yosemite-entry-reservations`}
            component={EntryReservationComponent}
          />

          <Route exact path={`/gallery`} component={GalleryComponent} />
          <Route exact path={`/gallery/:id`} component={GalleryComponent} />

          <Route
            exact
            path={`/lost-found`}
            render={() => (
              <LostAndFoundComponent propertyData={props.propertyData} />
            )}
          />

          <Route
            exact
            path={`/six-plus-booking`}
            render={() => (
              <GroupTravelComponent propertyData={props.propertyData} />
            )}
          />

          <Route exact path={`/packages`} component={SpecialComponent} />
          <Route
            exact
            path={`/packages/:subdomain`}
            component={SpecialDetailsComponent}
          />

          <Route exact path={`/news`} component={NewsComponent} />
          <Route
            exact
            path={`/news/:id/:news_title`}
            component={NewsInnerPage}
          />
          <Route
            exact
            path={`/unsubsribe_me/:sub_id/:token`}
            component={UnsubscribeMail}
          />

          {propertyData.property_id === "761723" ? (
            <Route exact path={`/careers`} component={JobsComponent} />
          ) : (
            <Route exact path={`/Jobs`} component={JobsComponent} />
          )}
          <Route
            exact
            path={`/Jobs-Details/:jid/:jtitle`}
            render={(props) => <JobsDetailsComponent {...props} />}
          />
          <Route
            exact
            path={`/Jobs-Details/:id/:job_sub_url`}
            render={(props) => <JobsDetailsComponent {...props} />}
          />
          <Route exact path={`/pets`} component={PetsComponent} />
          <Route
            exact
            path={`/personal-info-request-form`}
            render={() => (
              <PersonalDataRequestComponent propertyData={props.propertyData} />
            )}
          />
          <Route exact path={`/weather`} component={WeatherComponent} />

          <Route
            exact
            path={`/privacy-policy`}
            component={PrivacyPolicyComponent}
          />
          <Route
            exact
            path={`/terms-conditions`}
            component={TermsAndConditionComponent}
          />
          <Route exact path={`/sitemap`} component={SiteMapComponent} />
          <Route
            exact
            path={`sitemap/:attraction_url`}
            component={AttractionDetailsPage}
          />

          <Route exact path={`/news/:news_title`} component={NewsInnerPage} />
          <Route exact path={`/faq`} component={FaqComponent} />

          <Route exact path={`/Pets`} component={PetsComponent} />

          <Route exact path={`/weather`} component={WeatherComponent} />

          <Route
            exact
            path={`/ada-accessibility-amenities-services-facilities`}
            component={AccessibilityComponent}
          />

          <Route
            exact
            path={`/terms-conditions`}
            component={TermsAndConditionComponent}
          />

          <Route
            exact
            path={`/cleaning-protocols`}
            component={CleaningProtocolComponent}
          />

          <Route exact path={`/friends/`} component={FriendDetailsPage} />

          <Route exact path={`/cookies`} component={CookiePolicyComponent} />
          <Route
            exact
            path={`/friend-details/:sub_url`}
            component={FriendsComponent}
          />

          <Route exact path={`/directions`} component={DirectionComponent} />
          <Route
            exact
            path={`/directions/:attraction_name/:address1/:city/:state/:zip_code`}
            component={DirectionComponent}
          />
          <Route
            exact
            path={`/directions/:address1/:city/:state/:zip_code`}
            component={DirectionComponent}
          />
          <Route
            exact
            path={`/directions/:sub_url/:address`}
            component={DirectionComponent}
          />
          <Route
            exact
            path={`/directions/:name/:adr_address`}
            component={DirectionComponent}
          />
          <Route
            exact
            path={`/directions/:entertainment_place/:entertainment_country/:entertainment_no`}
            component={DirectionComponent}
          />

          {/* Attraction page  */}
          {propertyData.property_id === "726622" ? (
            <Route
              exact
              path={`/Long-Beach-attractions`}
              component={AttractionComponent}
            />
          ) : propertyData.property_id === "761729" ? (
            <Route
              exact
              path={`/Wilmington-attractions`}
              component={HotelLelaAttraction}
            />
          ) : (
            <Route
              exact
              path={`/${propertyData?.city?.split(" ").join("-")}-attractions`}
              component={AttractionComponent}
            />
          )}

          {/* Attraction page  */}

          <Route exact path={`/credit-card`} component={CreditCardpage} />
          <Route
            exact
            path={`/3d-virtual-tours`}
            component={VirtualToursPage}
          />
          <Route exact path={`/things-to-do`} component={ThingsToDoPage} />
          {propertyData.property_id == "265" && (
            <Route exact path={`/hiking`} component={Hiking} />
          )}
          <Route exact path={`/videos`} component={VideoComponent} />
          <Route exact path={`/reviews`} component={ReviewComponent} />
          <Route
            exact
            path={`/reviews/:ReviewTitle/:review_id`}
            component={ReviewDetailsPage}
          />
          <Route exact path={`/add-reviews`} component={AddReviewsPage} />
          <Route exact path={`/add-reviews1`} component={AddReviewsPage} />
          <Route
            exact
            path={`/attractions/:attraction_url`}
            component={AttractionDetailsPage}
          />
          <Route exact path={`/parking-page`} component={ParkingPage} />
          <Route exact path={`/pool`} component={PoolPage} />
          <Route
            exact
            path={`/transport-page`}
            component={TransportCommunicatePage}
          />
          <Route
            exact
            path={`/college-university`}
            component={CollegeAndUniversiy}
          />
          <Route exact path={`/art-culture`} component={ArtCulturePage} />

          <Route
            exact
            path={`/local-service-page`}
            component={LocalServicePage}
          />
          <Route exact path={`/entertainment`} component={EntertainmentPage} />

          {propertyData.property_id === "726622" && (
            <Route
              exact
              path={`/long-beach-cruise-terminal`}
              component={LongBeachCruiseTerminal}
            />
          )}
          {propertyData.property_id === "726622" && (
            <Route
              exact
              path={`/cal-state-university-long-beach`}
              component={CalStateUniversityLongBeach}
            />
          )}

          {window.location.pathname !== "/" && (
            <Route path="*" component={PageNotFound} />
          )}
        </Switch>
      </Suspense>
    </>
  );
};
export default AppRouting;
