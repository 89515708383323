import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { propertyDataSelector, themeSelector } from "../Recoil/themeModule";

const SchemeComponent = () => {
  const [propertyData, setPropertyData] = useRecoilState(propertyDataSelector);

  console.log(propertyData.city);

  useEffect(() => {
    const jsonLd = {
      "@context": "https://schema.org/",
      "@type": "WebPage",
      name: `Hotel Near ${propertyData.city} , ${propertyData.property_name}`,
      speakable: {
        "@type": "SpeakableSpecification",
        xpath: [
          "/html/head/title",
          "/html/head/meta[@name='description']/@content",
        ],
      },
      url: `${propertyData.website}`,
    };

    const scriptTag = document.createElement("script");
    scriptTag.type = "application/ld+json";
    scriptTag.text = JSON.stringify(jsonLd);

    document.body.appendChild(scriptTag);

    return () => {
      // Clean up the script tag if the component is unmounted
      document.body.removeChild(scriptTag);
    };
  }, []);

  return null; // The component doesn't render any visible content
};

export default SchemeComponent;
