import React, { useEffect } from "react";
import BannerContainer from "./E/InnerPages/BannerComponent/BannerContainer";
import Erroricon from "./E/CommonAssets/images/pagenotfound-e.jpg";
import { Link } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  seoThemeDetails,
  getSeoIdSelector,
  getSeoDataSelector,
  themeSelector,
  propertyDataSelector,
} from "./Recoil/themeModule";

export default function PageNotFound() {
  const [seoId, setSeoId] = useRecoilState(getSeoIdSelector);
  const { overview } = useRecoilValue(seoThemeDetails);
  const getSeoDetails = useRecoilValue(getSeoDataSelector);
  const [themeData, setThemeData] = useRecoilState(themeSelector);
  const propertyData = useRecoilValue(propertyDataSelector);
  useEffect(() => {
    updateSeoId();
  }, []);

  const updateSeoId = () => {
    setSeoId(overview);
  };

  return (
    <div className="container-fluid px-0">
      <BannerContainer seoData={getSeoDetails} />
      <div className="container error_box my-4">
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 mx-auto text-center">
            <div>
              {" "}
              <img src={Erroricon} alt="404 " className="w-100 mt-5" />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
            <div className="text-center error_right py-4">
              <div className="oops">Oops... Page Not Found!</div>
              <ul className="mt-4 px-0 links-icon ">
                <Link to="/">
                  <li className="fs-16">Home Page</li>
                </Link>
                <Link to="/contact-us">
                  <li className="fs-16"> Contact Us</li>
                </Link>
                <Link
                  to="/reservations"
                  target={
                    themeData?.is_marketing == 1 || propertyData?.is_ibe == "1"
                      ? "_blank"
                      : "_self"
                  }
                  onClick={() => {
                    if (themeData?.is_marketing == 1) {
                      localStorage.setItem(
                        "state",
                        JSON.stringify({
                          roomNo: 1,
                          adultNo: 1,
                          startDate: new Date(),
                          endDate: new Date(Date.now() + 3600 * 1000 * 24),
                        })
                      );
                    }
                  }}
                >
                  <li className="fs-16"> Reservations</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
