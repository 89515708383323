import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import "./MainFooter.scss";
import { useRecoilState, useRecoilValue } from "recoil";
import LanguageTranslate from "../../../languageTest";
import { FaWheelchair } from "react-icons/fa";
import SocialIconComponent from "../Home/sections/socialIcons.js";
import {
  getSideNavSelector,
  propertyDataSelector,
  themeSelector,
} from "../../../Recoil/themeModule";
import hcLogo from "../assets/images/hc-footer-logo.png";
import atriumLogo from "../assets/images/hc-atrium-logo.png";
import { getSubscribeEmailData } from "../../../DataLayer/datalayerUtilities";
import ReCAPTCHA from "react-google-recaptcha";
// signup
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

const MainFooter = (allpropertydata) => {
  const [sideNav, setSideNav] = useRecoilState(getSideNavSelector);
  const recaptchaRef = useRef(null);
  const formSchema = Yup.object().shape({
    subscriber_email: Yup.string()
      .required("Please Enter Your Email Address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Please Enter Valid Email Address"
      ),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
  } = useForm(formOptions);

  const propertyData = useRecoilValue(propertyDataSelector);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  const themeSelectorData = useRecoilValue(themeSelector);
  const invisibleCaptchaKey = themeSelectorData.invisiblenoCaptchaSiteKey;
  const pathname = useLocation();

  const waveerro = document.querySelectorAll("h2.mb-3");

  for (let i = 0; i < waveerro.length; i++) {
    waveerro[i].textContent = "Yosemeite";
    waveerro[i].classList.add("d-none");
  }

  // Sojern Script - PM edits
  useEffect(() => {
    setTimeout(() => {
      import("../../../Sojern.js").then(() => {
        console.log("Sojern.js imported");
      });
    }, 10000);
  }, []);
  // Sojern Script - PM edits

  useEffect(() => {
    if (errors.subscriber_email) {
      alert(errors.subscriber_email.message);
    }
  }, [errors]);

  // pm edits h1scroll

  const h1scroll = () => {
    const element = document.querySelector("h1");
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const distanceToTop = elementRect.top + window.scrollY;
      if (window.screen.availWidth > 1199) {
        let checkAvail =
          document.querySelector("#showCAonClickBtn").offsetHeight || 115;
        window.scrollTo({
          top: distanceToTop - checkAvail - 10,
          behavior: "smooth",
        });
      } else {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (sideNav === false && pathname.pathname !== "/") {
      setTimeout(() => {
        h1scroll();
      }, 2000);
    }
  }, [pathname.pathname]);
  // pm edits h1scroll

  const onSubmit = async (data) => {
    const logo_path = "";
    const subscriber_name = "";
    var dateWhen = Date().toLocaleString();
    const recaptchaValue = await recaptchaRef.current.executeAsync();
    const { email, property_name } = propertyData;

    const finalData = {
      ...data,
      logo_path,
      subscriber_name,
      dateWhen,
      property_name,
      email,
      captcha: recaptchaValue,
      theme: themeSelectorData.theme,
    };

    const response = await getSubscribeEmailData(finalData);

    if (response.data === "success") {
      alert("Thank You! You Have Successfully Subscribed To The Mailing List.");
      document.getElementById("subscriber_email").value = "";
    } else if (response.data === "Erremail") {
      alert(
        "Sorry, Your Email Address Is Already Subscribed. Please Enter Another Email Address."
      );
    } else if (response.data === "Invalid") {
      if (document.getElementById("subscriber_email").value == "") {
        alert("Enter Your Email Address.");
      } else {
        alert("Sorry, You Have Entered An Invalid Email Address.");
      }
    } else {
      alert(
        "We Are Sorry, We Have Experienced A Technical Issue. Please Try Again After Some Time."
      );
    }

    reset({
      subscriber_email: "",
    });
    if (recaptchaRef?.current) {
      recaptchaRef?.current.reset();
    }
  };
  let alttitleImgLoc = `${propertyData.property_name} - ${propertyData.street_address} ${propertyData.city},${propertyData?.country} ${propertyData.state}, ${propertyData.zip_code}`;

  return (
    <div className="Footer-Style-hotelCurrent px-0 mt-0 mt-lg-0 common-footer">
      <section className="hc-subscription p-2 p-md-4">
        <h2
          className="footerTitle"
          data-aos="flip-right"
          data-aos-duration="1000"
        >
          SIGN UP FOR
        </h2>
        <h2
          className="footerSubTitle"
          data-aos="flip-left"
          data-aos-duration="1200"
        >
          Our Newsletter
        </h2>
        <div className="row mx-0 text-center text-white">
          <div className="col-12 d-flex my-2 px-0">
            <div className="col-12 subscribe_wrapper d-flex">
              <div className="mx-auto subscribe-input-text text-center text-white text-md-center d-flex flex-column justify-content-end">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="mt-3 btn-subscribe d-flex"
                >
                  <label
                    htmlFor="subscriber_email"
                    className="form-label customWaveErrorResolve"
                  >
                    sdfsdf
                  </label>

                  <input
                    id="subscriber_email"
                    placeholder="Enter Your Email Address"
                    className={`form-control form-controlled ltr-none bdr-left-rght-radius ${
                      errors.subscriber_email && "invalid"
                    }`}
                    {...register("subscriber_email", {})}
                    onKeyUp={() => {
                      trigger("subscriber_emails");
                    }}
                  />

                  <label
                    htmlFor="g-recaptcha-response"
                    className="customWaveErrorResolve"
                  >
                    g-recaptcha-response
                  </label>
                  <label
                    htmlFor="g-recaptcha-response-1"
                    className="customWaveErrorResolve"
                  >
                    g-recaptcha-response-1
                  </label>
                  <label
                    htmlFor="g-recaptcha-response-2"
                    className="customWaveErrorResolve"
                  >
                    g-recaptcha-response-2
                  </label>

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    //sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    sitekey={invisibleCaptchaKey}
                    size="invisible"
                  />
                  <button
                    className="footer-hc-btn"
                    type="submit"
                    title=" Sign Up"
                  >
                    Sign up
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hc-otherDetails">
        <div className="hc-site-links container">
          <div className="site1 p-4">
            {propertyData.overview_status && (
              <li>
                <Link to="/overview" title="About Us">
                  About us
                </Link>
              </li>
            )}

            {propertyData.innsight_status === "1" && (
              <li>
                <Link to="/reviews" title="Reviews">
                  Reviews
                </Link>
              </li>
            )}

            {propertyData?.direction_status && (
              <li>
                <Link to="/directions" title="Directions">
                  Directions
                </Link>
              </li>
            )}

            {propertyData.is_personal_info === "1" && (
              <li>
                <Link
                  to="/personal-info-request-form"
                  title="Personal Data Request"
                >
                  Personal Data Request
                </Link>
              </li>
            )}
          </div>
          <div className="site2 p-4">
            {propertyData.blogStatus === "1" && (
              <li>
                <Link to="/blog" title="Blogs">
                  Blogs
                </Link>
              </li>
            )}

            {propertyData.job_status && (
              <li>
                <Link to="/jobs" title="Jobs">
                  Jobs
                </Link>
              </li>
            )}

            {propertyData.faq_status === "1" && (
              <li>
                <Link to="/faq" title="FAQs">
                  FAQs
                </Link>
              </li>
            )}
          </div>
          <div className="site3 p-4">
            <li>
              <Link to="/sitemap" title="Sitemap">
                Sitemap
              </Link>
            </li>

            {propertyData.is_cookies_policy === "1" && (
              <li>
                <Link to="/cookies" title="Cookie Policy">
                  Cookie Policy
                </Link>
              </li>
            )}
            {propertyData.is_privacy_policy === "1" && (
              <li>
                <Link to="/privacy-policy" title="Privacy Policy">
                  Privacy Policy
                </Link>
              </li>
            )}

            {propertyData.is_terms_cond === "1" && (
              <li>
                <Link to="/terms-conditions" title="Terms & Conditions">
                  Terms & Conditions
                </Link>
              </li>
            )}
          </div>
          <div className="site4 p-4">
            <div
              className="cust-align-end PR lang-btn mt-0 mb-0 lang1"
              title="Languages"
            >
              <LanguageTranslate />
              <label htmlFor="goog-gt-votingInputSrcLang" className="d-none">
                one
              </label>
              <label htmlFor="goog-gt-votingInputTrgLang" className="d-none">
                two
              </label>
              <label htmlFor="goog-gt-votingInputSrcText" className="d-none">
                three
              </label>
              <label htmlFor="goog-gt-votingInputTrgText" className="d-none">
                four
              </label>
              <label htmlFor="goog-gt-votingInputVote" className="d-none">
                five
              </label>
            </div>

            <div>
              {propertyData.accessibilty_status && (
                <Link
                  to="/ada-accessibility-amenities-services-facilities"
                  title="Accessibility"
                  className="wheelchair-m d-flex justify-content-center"
                >
                  <span className="wheel-text d-flex button-ada text-decoration-none">
                    <FaWheelchair size="20" className="on-h-Icon mr-1" />
                    <span>Accessibility</span>
                  </span>
                </Link>
              )}
            </div>

            <div>
              <a
                href="https://res.windsurfercrs.com/ibe/change.aspx?pcode=26135&_gl=1*1pnaokr*_ga*MTMxNjE1MjYzMC4xNzIxMzg5Mjkz*_ga_ZGC5SB9GFJ*MTcyMTM4OTI5Mi4xLjAuMTcyMTM4OTI5Ni41Ni4wLjA."
                className="d-flex justify-content-center modify-reservation bg-info text-light px-3 py-2"
                title="Modify Reservations"
                target="blank"
              >
                <span>Modify Reservations</span>
              </a>
            </div>
          </div>
        </div>

        <div className="hc-social-section container px-0">
          <section className="socialLinks">
            <a href="/" title={alttitleImgLoc}>
              <img className="social-img" src={hcLogo} alt="hcLogo" />
            </a>
            <a
              href="https://atriumhotel.com/"
              target="_blank"
              rel="noreferrer"
              title="Atrium Hotel"
            >
              <img className="social-img" src={atriumLogo} alt="attriumLogo" />
            </a>
            <SocialIconComponent />
          </section>

          <section className="socialAttractions py-3 my-4">
            <div title="Long Beach, CA Reservations">
              <Link
                className="wheelchair-m d-flex justify-content-center"
                to="/reservations"
                target="_blank"
                onClick={() => {
                  localStorage.setItem(
                    "state",
                    JSON.stringify({
                      roomNo: 1,
                      adultNo: 1,
                      startDate: startDate,
                      endDate: endDate,
                    })
                  );
                }}
              >
                <span className="wheel-text d-flex button-modify-reservation text-decoration-none">
                  <span>Long Beach, CA Reservations</span>
                </span>
              </Link>
            </div>

            <div title="Long Beach, CA Hotels">
              {" "}
              <Link to="/overview">Long Beach, CA Hotels</Link>
            </div>
            <div title="Long Beach, CA Hotel Rooms">
              <Link to="/guestrooms">Long Beach, CA Hotel Rooms</Link>
            </div>
            <div title="Attractions in Long Beach, CA">
              <Link to={`/Long-Beach-attractions`}>
                Attractions in Long Beach, CA
              </Link>
            </div>
          </section>
        </div>

        <div
          className={
            pathname.pathname === "/overview"
              ? "hc-credit-section copySec"
              : "hc-credit-section"
          }
        >
          <div className="hc-custom-inn">
            Website Design, Development, and Digital Marketing{" "}
            <a
              className="hc-innsight-link"
              href="https://www.innsight.com/"
              target="_blank"
              rel="noreferrer"
            >
              Powered by INNsight
            </a>
            .
          </div>
          <div>Copyright © {new Date().getFullYear()} INNsight.com, Inc.</div>
        </div>
      </section>
    </div>
  );
};

export default MainFooter;
