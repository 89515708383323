import React, { useState, useEffect, lazy } from "react";
import { withRouter } from "react-router-dom";
import ReservationBotton from "../Home/sections/ReservationButton";
import "./sideApp.scss";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  getSideMenuSelector,
  propertyDataSelector,
} from "../../../Recoil/themeModule";
import { IoIosArrowUp } from "react-icons/io";
import StartMenuComponent from "./StartMenu.js";
import CurrentLogo from "../../HotelCurrent/assets/images/currentLogo.png";
import { FaPhoneAlt } from "react-icons/fa";
if (
  window.location.href.includes("hotelcurrent") ||
  window.location.href.includes("localhost")
) {
  var SlideMenuComponent = lazy(() =>
    import("../../HotelCurrent/slidemenu/slidemenu")
  );
}
const Header = ({ history }) => {
  const [showSideMenu, setShowSideMenu] = useRecoilState(getSideMenuSelector);
  const [visible, setVisible] = useState(false);
  const propertyData = useRecoilValue(propertyDataSelector);

  let dPhone = `${
    propertyData &&
    propertyData.primary_phone_no &&
    propertyData.primary_phone_no.split("/")[1]
  }`;

  useEffect(() => {
    window.addEventListener("scroll", myScrollFunc);
  }, []);
  var myScrollFunc = function () {
    var y = window.scrollY;
    if (y >= 500) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const element = document.getElementsByClassName("custom_IH_wrapper")[0];
    if (element) {
      if (width >= 2090) {
        element.classList.remove("container");
      } else {
        element.classList.add("container");
      }
    }
  }, [width]);
  useEffect(() => {
    const element = document.getElementsByClassName("custom_IH_wrapper")[0];
    if (element) {
      if (width >= 2090) {
        element.classList.remove("container");
      } else {
        element.classList.add("container");
      }
    }
  }, []);
  let alttitleImgLoc = `${propertyData.property_name} - ${propertyData.street_address} ${propertyData.city},${propertyData?.country} ${propertyData.state}, ${propertyData.zip_code}`;

  useEffect(() => {
    setTimeout(() => {
      setShowSideMenu(true);
    }, 2000);
  }, []);

  return showSideMenu ? (
    <div className="Main-header-links hotelCurrentHeader">
      <header className="">
        <StartMenuComponent />
        <div className="container container-mob-view px-0 custom_IH_wrapper">
          <div className="inner-header">
            <div className="headMenuLogoDiv">
              {window.innerWidth > 1199 ? (
                <div className="currenlogo" title={alttitleImgLoc}>
                  <a href="/">
                    <img src={CurrentLogo} alt="Current Logo" />
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>

            {window.innerWidth > 1199 ? (
              <div className="headphSec">
                <p className="Phone-text mt-0 mb-2 text-dark pt-2 Phone">
                  {" "}
                  <a
                    className="text-decoration-none"
                    title={dPhone}
                    href={`tel:${
                      propertyData &&
                      propertyData.primary_phone_no &&
                      propertyData.primary_phone_no.split("/")[1]
                    }`}
                  >
                    <span>
                      <FaPhoneAlt />
                    </span>{" "}
                    {propertyData &&
                      propertyData.primary_phone_no &&
                      propertyData.primary_phone_no.split("/")[1]}
                  </a>
                </p>
              </div>
            ) : (
              ""
            )}

            <div className="menuReserDiv">
              <div className="reservDiv">
                <div className="reservationBtn">
                  <ReservationBotton />
                </div>
              </div>
              <div className="humburgMenuDiv">
                <SlideMenuComponent />
              </div>
            </div>

            <div
              data-aos="zoom-in-up"
              data-aos-duration="1000"
              className="scrollBtn"
            >
              {visible && (
                <button
                  className="scrolBtnStyle"
                  type="button"
                  id="scrollTop"
                  title="Scroll To Top"
                >
                  scrollBtn
                  <IoIosArrowUp onClick={scrollToTop} />
                </button>
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  ) : (
    <div></div>
  );
};

export default withRouter(Header);
