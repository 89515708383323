import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import ReservationBotton from "../Home/sections/ReservationButton";
import logoone from "../assets/Icons/ywl.webp";
import logoonePNG from "../assets/Icons/ywl1.png";
import "./sideApp.scss";
import { FaArrowUp } from "react-icons/fa";
import { propertyDataSelector } from "../../../Recoil/themeModule";
import { useRecoilValue, useRecoilState } from "recoil";
import SlideMenuComponent from "../slidemenu/slidemenu";
import { getSideMenuSelector } from "../../../Recoil/themeModule";

const Header = ({ history }) => {
  const [showSideMenu, setShowSideMenu] = useRecoilState(getSideMenuSelector);
  const [visible, setVisible] = useState(false);
  const propertyData = useRecoilValue(propertyDataSelector);

  useEffect(() => {
    window.addEventListener("scroll", myScrollFunc);
  }, []);
  var myScrollFunc = function () {
    var y = window.scrollY;
    if (y >= 500) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  let logoTitle = `${propertyData.property_name} - ${propertyData.street_address}, ${propertyData.city}, ${propertyData.state}, ${propertyData.zip_code}`;

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const element = document.getElementsByClassName("custom_IH_wrapper")[0];
    if (element) {
      if (width >= 2090) {
        element.classList.remove("container");
      } else {
        element.classList.add("container");
      }
    }
  }, [width]);
  useEffect(() => {
    const element = document.getElementsByClassName("custom_IH_wrapper")[0];
    if (element) {
      if (width >= 2090) {
        element.classList.remove("container");
      } else {
        element.classList.add("container");
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowSideMenu(true);
    }, 2000);
  }, []);

  return showSideMenu ? (
    <header>
      <div className="container container-mob-view px-0 custom_IH_wrapper">
        <div className="inner-header">
          <div className="headMenuLogoDiv">
            <div className="logo col-xl-2 col-lg-2">
              <figure className="PR">
                <Link to="/">
                  <picture className="desktopImage w-100">
                    <source
                      className=""
                      type="image/webp"
                      srcSet={logoone}
                      alt="logo"
                    ></source>
                    <source
                      className=""
                      type="image/jpeg"
                      srcSet={logoonePNG}
                      alt="logo"
                    ></source>
                    <img
                      src={logoonePNG}
                      className="ywg-logo img-fluid"
                      width="100%"
                      height="100%"
                      alt={logoTitle}
                      title={logoTitle}
                    />
                  </picture>
                  <figcaption
                    className="logo-caption"
                    title="Yosemite Westgate Lodge - 7633 State Highway 121, Groveland, California, 95321 "
                  >
                    by BLINK HOTELS<sup>TM</sup>
                  </figcaption>
                </Link>
              </figure>
            </div>
          </div>
          <div className="menuReserDiv">
            <div className="reservDiv">
              <div className="reservationBtn">
                <ReservationBotton />
              </div>
            </div>
            <div className="humburgMenuDiv">
              <SlideMenuComponent />
            </div>
          </div>
          <div
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            className="scrollBtn"
          >
            {visible && (
              <button
                className="scrolBtnStyle"
                type="button"
                id="scrollTop"
                title="Scroll To Top  "
              >
                scrollBtn
                <FaArrowUp onClick={scrollToTop} />
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  ) : (
    <div></div>
  );
};

export default withRouter(Header);
