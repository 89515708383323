import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import headerLogo from "../assets/Icons/header-logo.svg";
import "./StartMenu.scss";
import SantaCruze from "../../SantaCruzComfortInn/assets/Icons/header-logo.svg";
import { propertyDataSelector } from "../../../Recoil/themeModule";
import { useRecoilValue } from "recoil";
import { FaArrowUp } from "react-icons/fa";
import { BiSolidChevronDown } from "react-icons/bi";

function StartMenuComponent() {
  const [isSticky, setIsSticky] = useState(false);
  const [visible, setVisible] = useState(false);
  const propertyData = useRecoilValue(propertyDataSelector);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [show, setShow] = useState({
    gallery: false,
    events: false,
    destinations: false,
  });

  useEffect(() => {
    window.addEventListener("scroll", myScrollFunc);
  }, []);
  var myScrollFunc = function () {
    var y = window.scrollY;
    if (y >= 500) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setShow({
      gallery: false,
      events: false,
      destinations: false,
    });
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown, so close it
        setShow({
          gallery: false,
          events: false,
          destinations: false,
        });
      }
    };

    if (show.gallery || show.events || show.destinations) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [show.gallery, show.events, show.destinations]);
  let alttitleImgLoc1 = `${propertyData.property_name} - ${propertyData.street_address}, ${propertyData.city}, ${propertyData.state}, ${propertyData.zip_code},${propertyData?.country}`;

  return (
    <div className={`SideAppStyle StartMenuStyle`}>
      {window.innerWidth > 1199 ? (
        <div className="desk-menu">
          <div className="grid-container-list">
            <ul className="grid-list d-flex justify-content-around align-items-center mb-0">
              <div>
                <li>
                  <Link to="/">
                    <img
                      src={headerLogo}
                      alt="headerLogo"
                      title={alttitleImgLoc1}
                      width="90"
                      height="90"
                    />
                  </Link>
                </li>
              </div>

              <div className="custome-menu-css">
                {propertyData.overview_status == "1" && (
                  <li>
                    <Link
                      title="Overview"
                      to="/overview"
                      className={
                        location.pathname === "/overview" ? "active" : ""
                      }
                    >
                      OVERVIEW
                    </Link>
                  </li>
                )}

                {propertyData.guest_room_status === "1" && (
                  <li>
                    <Link
                      title="Guest Rooms"
                      to="/guestrooms"
                      className={
                        location.pathname === "/guestrooms" ||
                        location.pathname ===
                          `/guestrooms/${window.location.href.split("/")[4]}`
                          ? "active"
                          : ""
                      }
                    >
                      GUEST ROOMS
                    </Link>
                  </li>
                )}

                {propertyData.innsight_status === "1" && (
                  <li>
                    <Link
                      title="Reviews"
                      to="/reviews"
                      className={
                        location.pathname === "/reviews" ? "active" : ""
                      }
                    >
                      REVIEWS
                    </Link>
                  </li>
                )}

                {propertyData.panographic_status === "1" ||
                propertyData.video_status === "1" ? (
                  <li>
                    <div className="dropdown" ref={dropdownRef}>
                      <div
                        className={
                          location.pathname === "/gallery" ||
                          location.pathname === "/3d-virtual-tours" ||
                          location.pathname === "/videos"
                            ? "active dropdown-trigger"
                            : "dropdown-trigger"
                        }
                        title="Gallery"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShow({
                            gallery: !show.gallery,
                            events: false,
                            destinations: false,
                          });
                        }}
                      >
                        GALLERY
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 24 24"
                          height="20"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M16.939 7.939 12 12.879l-4.939-4.94-2.122 2.122L12 17.121l7.061-7.06z"></path>
                        </svg>
                      </div>{" "}
                      {show.gallery && (
                        <div className="dropdown-content gallery-content">
                          {propertyData.gallery_status === "1" && (
                            <Link
                              to="/gallery"
                              title="Pictures"
                              className={
                                location.pathname === "/gallery"
                                  ? "active dropbtn"
                                  : ""
                              }
                            >
                              Pictures
                            </Link>
                          )}
                          {propertyData.panographic_status === "1" && (
                            <Link
                              to="/3d-virtual-tours"
                              title="3D Virtual Tours"
                              className={
                                location.pathname === "/3d-virtual-tours"
                                  ? "active dropbtn"
                                  : ""
                              }
                            >
                              3D Virtual Tours
                            </Link>
                          )}
                          {propertyData.video_status === "1" && (
                            <Link
                              to="/videos"
                              title="Videos"
                              className={
                                location.pathname === "/videos"
                                  ? "active dropbtn"
                                  : ""
                              }
                            >
                              Videos
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                ) : propertyData.gallery_status === "1" ? (
                  <li>
                    <Link
                      to="/gallery"
                      title="Gallery"
                      className={
                        location.pathname === "/gallery" ? "active" : ""
                      }
                    >
                      GALLERY
                    </Link>
                  </li>
                ) : null}

                {(propertyData.attraction_status === "1" ||
                  propertyData.thingstodoStatus === "1" ||
                  propertyData.your_guide_onoff_status === "1" ||
                  propertyData.weather_status === "1") && (
                  <li>
                    <div className="dropdown" ref={dropdownRef}>
                      {" "}
                      <div
                        title="Destination"
                        className={
                          location.pathname ===
                            `/${propertyData?.city
                              .split(" ")
                              .join("-")}-attractions` ||
                          location.pathname === "/things-to-do" ||
                          location.pathname === "/guided-tours" ||
                          location.pathname === "/weather"
                            ? "active dropdown-trigger"
                            : "dropdown-trigger"
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          setShow({
                            gallery: false,
                            events: false,
                            destinations: !show.destinations,
                          });
                        }}
                      >
                        DESTINATION
                        <BiSolidChevronDown size={20} />
                      </div>{" "}
                      {show.destinations && (
                        <div className="dropdown-content">
                          {propertyData.attraction_status === "1" && (
                            <Link
                              title="Attractions"
                              className={
                                location.pathname ===
                                `/${propertyData?.city
                                  .split(" ")
                                  .join("-")}-attractions`
                                  ? "active dropbtn"
                                  : ""
                              }
                              to={`/${propertyData?.city
                                ?.split(" ")
                                .join("-")}-attractions`}
                            >
                              Attractions
                            </Link>
                          )}

                          {propertyData.thingstodoStatus === "1" && (
                            <Link
                              title="Things To Do"
                              to="/things-to-do"
                              className={
                                location.pathname === "/things-to-do"
                                  ? "active dropbtn"
                                  : ""
                              }
                            >
                              Things To Do
                            </Link>
                          )}

                          {propertyData.your_guide_onoff_status === "1" && (
                            <Link
                              title="Guided Tours"
                              to="/guided-tours"
                              className={
                                location.pathname === "/guided-tours"
                                  ? "active dropbtn"
                                  : ""
                              }
                            >
                              Guided Tours
                            </Link>
                          )}
                          {propertyData.weather_status === "1" && (
                            <Link
                              title="Weather"
                              to="/weather"
                              className={
                                location.pathname === "/weather"
                                  ? "active dropbtn"
                                  : ""
                              }
                            >
                              Weather
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                )}
              </div>
            </ul>
          </div>
        </div>
      ) : (
        <div className="header-mob">
          <div className="logo-mob">
            <Link to="/">
              <img src={SantaCruze} alt="SantaCruze" />
            </Link>
          </div>
          <div className="menu-mob"></div>
        </div>
      )}

      <div data-aos="zoom-in-up" data-aos-duration="1000" className="scrollBtn">
        {visible && (
          <button className="scrolBtnStyle" type="button" id="scrollTop">
            scrollBtn
            <FaArrowUp onClick={scrollToTop} />
          </button>
        )}
      </div>
    </div>
  );
}

export default StartMenuComponent;
