import React, { useState, useEffect, lazy } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import ReservationBotton from "../Home/sections/ReservationButton";
import "./sideApp.scss";
import { FaArrowUp } from "react-icons/fa";
import {
  getSideMenuSelector,
  propertyDataSelector,
} from "../../../Recoil/themeModule";
import { useRecoilState, useRecoilValue } from "recoil";
import StartMenuComponent from "./StartMenu.js";
import LocationIcon from "../../SantaCruzComfortInn/assets/Icons/location-header.svg";
import CallIcon from "../../SantaCruzComfortInn/assets/Icons/header-call.svg";
if (
  window.location.href.includes("santacruzcomfortinn") ||
  window.location.href.includes("localhost")
) {
  var SlideMenuComponent = lazy(() =>
    import("../../SantaCruzComfortInn/slidemenu/slidemenu")
  );
}
const Header = ({ history }) => {
  const [showSideMenu, setShowSideMenu] = useRecoilState(getSideMenuSelector);
  const propertyData = useRecoilValue(propertyDataSelector);
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", myScrollFunc);
  }, []);
  var myScrollFunc = function () {
    var y = window.scrollY;
    if (y >= 500) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const element = document.getElementsByClassName("custom_IH_wrapper")[0];
    if (element) {
      if (width >= 2090) {
        element.classList.remove("container");
      } else {
        element.classList.add("container");
      }
    }
  }, [width]);
  useEffect(() => {
    const element = document.getElementsByClassName("custom_IH_wrapper")[0];
    if (element) {
      if (width >= 2090) {
        element.classList.remove("container");
      } else {
        element.classList.add("container");
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowSideMenu(true);
    }, 2000);
  }, []);

  if (location.pathname === "/reservations") {
    return null;
  }

  let alttitleImgLoc1 = `${propertyData.property_name} - ${propertyData.street_address}, ${propertyData.city}, ${propertyData.state}, ${propertyData.zip_code},${propertyData?.country}`;
  return showSideMenu ? (
    <div className="Main-header-links">
      <header className="d-flex align-items-center">
        {/* navbar menus  */}
        <StartMenuComponent />
        {/* navbar menus  */}

        {/* Reservation button  */}
        <div className="d-top-button">
          {/* Social icons  */}
          <div className="socialIconsStye mt-3 mt-md-1 mb-lg-0 pt-lg-0 navSocial d-lg-flex">
            <div className="container-fluid px-0 px-md-2">
              <ul className="list-unstyled dis-flex">
                <li className="mr-2">
                  <Link
                    className="mx-1"
                    title={alttitleImgLoc1}
                    to="/directions"
                  >
                    <img
                      src={LocationIcon}
                      alt="Location"
                      width="41"
                      height="41"
                    />
                  </Link>
                </li>

                <li>
                  <a
                    className="mx-1"
                    title={`${
                      propertyData &&
                      propertyData.primary_phone_no &&
                      propertyData.primary_phone_no.split("/")[1]
                    }`}
                    href={`tel:${
                      propertyData &&
                      propertyData.primary_phone_no &&
                      propertyData.primary_phone_no.split("/")[1]
                    }`}
                  >
                    <img src={CallIcon} alt="call" width="41" height="41" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="container container-mob-view px-0 custom_IH_wrapper">
            <div className="inner-header">
              <div className="menuReserDiv">
                <div className="reservDiv">
                  <div className="reservationBtn">
                    <ReservationBotton />
                  </div>
                </div>
                <div className="humburgMenuDiv">
                  <SlideMenuComponent />
                </div>
              </div>
              <div
                data-aos="zoom-in-up"
                data-aos-duration="1000"
                className="scrollBtn"
              >
                {visible && (
                  <button
                    className="scrolBtnStyle"
                    type="button"
                    id="scrollTop"
                    title="Scroll To Top"
                  >
                    scrollBtn
                    <FaArrowUp onClick={scrollToTop} />
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* Reservation button  */}
        </div>
        {/* Social icons  */}
      </header>
    </div>
  ) : (
    <div></div>
  );
};

export default withRouter(Header);
