import React from "react";
import { BiLogoFacebook, BiSolidDownArrow } from "react-icons/bi";
import "./social-Icons.scss";
import { AiOutlineGoogle, AiOutlineInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { useRecoilValue } from "recoil";
import { propertyDataSelector } from "../../../../Recoil/themeModule";

export default function SocialIconComponent() {
  const propertyData = useRecoilValue(propertyDataSelector);
  return (
    <div className="socialIconsStye mt-3 mt-md-1  mb-lg-0 pt-lg-0">
      <div className="social-container container-fluid px-0 px-md-2">
        <ul className="list-unstyled">
          {propertyData.facebook !== "" ? (
            <li>
              <a
                target="_blank"
                href={`${propertyData.facebook}`}
                className="mx-1"
                rel="noreferrer"
              >
                <span className="empty-div">No Text</span>
                <BiLogoFacebook className="icon" />
              </a>
              <span className="cus_tooltip">
                <span>Facebook </span> <BiSolidDownArrow />
              </span>
            </li>
          ) : (
            <li className="d-none"></li>
          )}

          {propertyData.instagram_url !== "" ? (
            <li>
              <a
                target="_blank"
                href={`${propertyData.instagram_url}`}
                className="mx-1"
                rel="noreferrer"
              >
                <span className="empty-div">No Text</span>
                <AiOutlineInstagram className="icon" />
              </a>
              <span className="cus_tooltip">
                <span>Instagram </span> <BiSolidDownArrow />
              </span>
            </li>
          ) : (
            <li className="d-none"></li>
          )}

          {propertyData.linkedin_url !== "" ? (
            <li>
              <a
                target="_blank"
                href={`${propertyData.linkedin_url}`}
                className="mx-1"
                rel="noreferrer"
              >
                <span className="empty-div">No Text</span>
                <AiFillLinkedin className="icon" />
              </a>

              <span className="cus_tooltip">
                <span>Linkedin </span> <BiSolidDownArrow />
              </span>
            </li>
          ) : (
            <li className="d-none"></li>
          )}
          {propertyData.google_url !== null ? (
            <li>
              <a
                target="_blank"
                href={propertyData.google_url}
                className="mx-1"
                rel="noreferrer"
              >
                <span className="empty-div">No Text</span>
                <AiOutlineGoogle className="icon" />
              </a>
              <span className="cus_tooltip">
                <span>Google</span> <BiSolidDownArrow />
              </span>
            </li>
          ) : (
            <li className="d-none"></li>
          )}
        </ul>
      </div>
    </div>
  );
}
